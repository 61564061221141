import React from 'react'
import styled from '@emotion/styled'
import { colors } from '@mobi/component-library/Theme/Common'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { Tabs, TabButton } from '@mobi/component-library/Navigation/Tabs'
import { useAppSelector } from './Store/hooks'
import { selectBetSlipItems } from './Store/Bets/selectors'
import { Singles, Multi, Footer, EmptyState } from './Components'

const enum LocalConstants {
  ScrollableClass = 'js-betslip__scrollable',
  FooterClass = 'js-betslip__footer',
}

export const BetSlip: React.FC = () => {
  const items = useAppSelector(selectBetSlipItems)

  // TODO: Add context so only mobi applies scrolllock
  const scrollableContinerRef = useBodyScrollLock(true)

  React.useEffect(() => {
    // TODO: Re-implement subscription for login/out events
    // const loggedInSubscription = userAccountState$
    //   .pluck<boolean>('isLoggedIn')
    //   .distinctUntilChanged()
    //   .subscribe(isLoggedIn => {
    //     isLoggedIn ? RefreshBetslip() : RemoveBonusBetsFromBetslip()
    //   })
  }, [])

  const hasItems = items?.length > 0

  return (
    <BetslipContainerStyled>
      <div>
        <Tabs>
          <TabButton name='Single' onClick={() => null} isSelected />
          <TabButton name='Multi' onClick={() => null} isSelected={false} />
        </Tabs>
      </div>

      <div ref={scrollableContinerRef} className={LocalConstants.ScrollableClass}>
        {hasItems ? (
          <>
            <Singles items={items} />

            <Multi />
          </>
        ) : (
          <EmptyState />
        )}
      </div>

      <div className={LocalConstants.FooterClass}>
        <Footer />
      </div>
    </BetslipContainerStyled>
  )
}

// ======
// Styles
// ======

const BetslipContainerStyled = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.surface[100],

  ['.' + LocalConstants.ScrollableClass]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  ['.' + LocalConstants.FooterClass]: {
    marginTop: 'auto',
  },
})
