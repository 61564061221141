import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import type { BetSlipItem, FobDetails } from '@mobi/betslip/types'

type SelectionDetails = Pick<
  FobDetails,
  | 'eventName'
  | 'competitionName'
  | 'sportIconCode'
  | 'tournamentName'
  | 'propositionName'
  | 'marketName'
  | 'handicapName'
  | 'eventStartTime'
>

export const FobBetInfo: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const {
    competitionName,
    eventName,
    handicapName,
    marketName,
    propositionName,
    sportIconCode,
    tournamentName,
    eventStartTime,
  } = item.selectionDetails as SelectionDetails

  const selection = `${propositionName} ${handicapName ? ` ${handicapName}` : ''}`

  return (
    <>
      <BetCard.EventDetails
        heading={tournamentName ? `${competitionName} ${tournamentName}` : `${competitionName}`}
        subHeading={eventName && eventName !== marketName ? eventName : ''}
        statusComponent={<CountdownBadge advertisedStartTime={new Date(eventStartTime)} />}
        iconComponent={<Icon size='2rem' type={sportIconCode || 'tab'} />}
      />

      <BetCard.SelectionInfo heading={selection} subHeading={marketName || ''} />
    </>
  )
}
