import type { RacingBetType } from '@mobi/betslip/types'
import { PlanSeq } from '@core/Data/betting'

export function getToteBetType(productPlanSequence: PlanSeq): RacingBetType | undefined {
  switch (productPlanSequence) {
    case PlanSeq.Win:
    case PlanSeq.Place:
    case PlanSeq.WinAndPlace:
      return 'Win & Place'

    case PlanSeq.Exacta:
    case PlanSeq.ExactaWithRovingBanker:
      return 'Exacta'

    case PlanSeq.Quinella:
      return 'Quinella'

    case PlanSeq.Trifecta:
    case PlanSeq.TrifectaWithRovingBanker:
      return 'Trifecta'

    case PlanSeq.First4:
    case PlanSeq.First4WithRovingBanker:
      return 'First 4'

    case PlanSeq.Double:
      return 'Double'

    case PlanSeq.Quaddie:
      return 'Quaddie'

    case PlanSeq.AllUp:
      return 'All Up'
  }

  return undefined
}
