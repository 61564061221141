import type {
  RacingBetType,
  AllUpSelection,
  FobMatchedSelection,
  FobPropositionSelection,
  FobSelection,
  FobSportsSelection,
  MysterySelection,
  SameRaceMultiSelection,
  Selection,
  StartingPriceFOOSelection,
  StartingPriceMatchedSelection,
  ToteSelection,
  EventDetails,
  RaceDetails,
  FobDetails,
  SportsDetails,
} from '../types'

export function isFobPropositionSelection(
  value: Selection | null
): value is FobPropositionSelection {
  return !!value && (value as FobPropositionSelection).type === 'fob-proposition'
}

export function isFobSelection(value: Selection | null): value is FobSelection {
  if (!value) return false
  const type = (value as FobSelection).type
  const fobProductTypes: FobSelection['type'][] = [
    'fob-proposition',
    'fob-matched',
    'same-race-multi',
    'fob-sports',
  ]
  return fobProductTypes.includes(type)
}

export function isFobSportsSelection(value: Selection | null): value is FobSportsSelection {
  return !!value && (value as FobSelection).type === 'fob-sports'
}

export function isFobMatchedSelection(value: Selection | null): value is FobMatchedSelection {
  return !!value && (value as FobMatchedSelection).type === 'fob-matched'
}

export function isSameRaceMultiSelection(value: Selection | null): value is SameRaceMultiSelection {
  return !!value && (value as SameRaceMultiSelection).type === 'same-race-multi'
}

export function isToteSelection(value: Selection | null): value is ToteSelection {
  return !!value && !!(value as ToteSelection).betType
}

export function isAllUpSelection(value: Selection | null): value is AllUpSelection {
  return !!value && !!(value as AllUpSelection).formulas
}

export function isMysterySelection(value: Selection | null): value is MysterySelection {
  return !!value && (value as MysterySelection).mysteryOption !== undefined
}

export function isNoveltyBetType(value: RacingBetType): boolean {
  return (
    value === 'Exacta' ||
    value === 'Quinella' ||
    value === 'Trifecta' ||
    value === 'First 4' ||
    value === 'Double' ||
    value === 'Quaddie'
  )
}

// TODO: Determine if local helper can replace
export function isMysteryComboBetType(selection: Selection | MysterySelection | null): boolean {
  return selection && isMysterySelection(selection) && selection.bets
    ? selection.productCode.toLowerCase() === 'combo'
    : false
}

export function isStartingPriceSelection(
  selection: Selection | null
): selection is StartingPriceMatchedSelection | StartingPriceFOOSelection {
  return !!(selection && 'marketCode' in selection && selection.marketCode === 'SP')
}

// Event Details type guards

export function isRaceDetails(details: EventDetails | null): details is RaceDetails {
  if (!details || typeof details !== 'object') return false
  const asRaceDetails = details as RaceDetails
  if (!asRaceDetails.races || !Array.isArray(asRaceDetails.races)) return false
  if (asRaceDetails.races.length > 0) return 'raceNumber' in asRaceDetails.races[0]
  return true
}

export function isFobDetails(details: EventDetails | null): details is FobDetails {
  if (
    !details ||
    typeof details !== 'object' ||
    typeof (details as FobDetails).propositionName !== 'string'
  )
    return false
  return true
}

export function isFobSportsDetails(details: EventDetails | null): details is SportsDetails {
  if (!details || typeof details !== 'object') return false
  return Boolean('sportName' in details && 'eventName' in details)
}
