import { FeatureFlags } from '@mobi/settings'
import { createBetSlipItem } from '@mobi/betslip/helpers/createBetSlipItem'
import { addItemsToBetSlip } from '@mobi/betslip/Store/Bets'
import { store } from '@core/Store'
import { state$ as featureState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { createItemForBetslip } from '@core/Areas/Betslip/helpers/addToBetslip'
import { AddSingleToBetslip } from '@core/Areas/Betslip/signals'
import { QUICKBET_MODAL_ID } from '@core/Areas/Quickbet/constants'
import { Quickbet, type QuickbetExternalProps } from '@core/Areas/Quickbet/Quickbet'
import {
  QuickbetClosed,
  QuickbetLoadSelection,
  QuickbetSelection,
} from '@core/Areas/Quickbet/signals'
import { openModal } from '@core/Components/Modal/store'
import { setBetSlipNewIsOpen } from '@core/Areas/AppHeader/Store'
import { convertToBetSelection } from './helpers/convertToBetSelection'
import { getEnhancedBetslipSetting } from '@core/Areas/Settings/Store/selectors'
import { selectBetSlipItemCount } from '@mobi/betslip/Store/Bets/selectors'

type HandleBetSelection = { selection: QuickbetSelection } & (
  | { location: 'Betslip'; options?: never }
  | {
      location: 'Quickbet'
      options?: {
        setUp?: () => void
        quickbetProps?: QuickbetExternalProps
      }
    }
)

export function handleBetSelection({ location, selection, options = {} }: HandleBetSelection) {
  featureState$.take(1).subscribe(featureState => {
    const isNewBetSlipActive = featureState.features.get(FeatureFlags.NEW_BET_SLIP_DESIGN.key)
    if (isNewBetSlipActive) {
      const prevBetslipItemsCount = selectBetSlipItemCount(store.getState())
      const item = createBetSlipItem(convertToBetSelection(selection))
      store.dispatch(addItemsToBetSlip([item]))

      if (location === 'Quickbet') {
        const isSmartbetslipEnabled = getEnhancedBetslipSetting(store.getState())
        if (isSmartbetslipEnabled && prevBetslipItemsCount > 0) return

        store.dispatch(setBetSlipNewIsOpen(true))
      }

      return
    } else {
      // Handle direct addition to Betslip (ie. from BettingDrawer)
      if (location === 'Betslip') {
        AddSingleToBetslip(createItemForBetslip(selection))

        return
      }

      // Handle opening Quickbet with selection
      if (location === 'Quickbet') {
        QuickbetLoadSelection(selection)

        if (options.setUp) options.setUp()

        store.dispatch(
          openModal({
            id: QUICKBET_MODAL_ID,
            modalComponent: Quickbet,
            color: 'dark',
            onCloseCallback: QuickbetClosed,
            modalComponentProps: options.quickbetProps as Record<string, unknown>,
          })
        )

        return
      }
    }
  })
}
