import styled from '@emotion/styled'
import { BetInvestmentGroupStyled } from '../../../Common/BetInvestment.styles'
import { MultiDetailsStyled, MultiDetailsAmountStyled } from '../../Multi.styles'

export const MultiFormulaExpandablePanelStyled = styled.div({
  borderTop: '0.1rem solid #ccc',
  marginTop: '0.25rem',
})

export const FormulaTitleTextStyled = styled.strong({
  fontSize: '1.2rem',
  display: 'block',
  color: 'black',
})

export const FormulaContainerStyled = styled.div({
  marginTop: '0.5rem',
})

export const FormulaItemStyled = styled.div({
  marginBottom: '0.5rem',
  padding: '0.5rem 0',
  borderBottom: `0.1rem solid #ccc`,

  '&:last-child': {
    borderBottom: 'none',
    marginBottom: 0,
  },
})

export const FormulaComboTextStyled = styled(MultiDetailsStyled)({
  padding: 0,
})

export const FormulaTextStyled = MultiDetailsAmountStyled

export const FormulaInvestmentStyled = styled(BetInvestmentGroupStyled)({
  justifyContent: 'flex-end',
})

export const FormulaCostContainerStyled = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export const FormulaCostChildStyled = styled.div<{ isQuickEdit?: boolean }>(
  {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    lineHeight: 1,
    fontSize: '1rem',
    height: '1.5rem',
    width: '33%',
    marginTop: '-0.2rem',
  },
  ({ isQuickEdit }) => ({
    color: isQuickEdit ? 'black' : 'red',
  })
)

export const FormulaCostAmountStyled = styled.span<{ isQuickEdit?: boolean }>(
  {
    fontSize: '1.2rem',
  },
  ({ isQuickEdit }) => ({
    color: isQuickEdit ? 'black' : 'red',
  })
)
