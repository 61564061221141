import * as immutable from 'immutable'
import type { Selection, FobSelection } from '@mobi/betslip/types'
import {
  isFobDetails,
  isFobSelection,
  isRaceDetails,
  isStartingPriceSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { BetslipItem, BetslipInvestment, MultiBetError, MultiInvestment } from '../driver'
import { BetslipResponse } from '@core/Data/betslip'
import {
  BetErrorType,
  BetError,
  SingleErrorMapping,
  MultiBetFatalErrorMapping,
  BetPrices,
  MultiErrorMapping,
} from '@core/Data/betting'
import { KeypadModes } from '@core/Components/Keypad/KeyPress'
import { MIN_LEGS_IN_MULTI, MAX_LEGS_IN_RACE_MULTI, MAX_LEGS_IN_SPORT_MULTI } from '../constants'

export const isUnspecifiedErrorType = (errorType: BetErrorType | undefined): boolean =>
  errorType === BetErrorType.Unspecified

export const isFatalErrorType = (betErrorType: BetErrorType | string | undefined): boolean => {
  if (!betErrorType) {
    return false
  }

  const betError = toBetErrorType(betErrorType)
  return betError === BetErrorType.Closed || betError === BetErrorType.HandicapChanged
}

export const isNonHandledError = (betErrorType: BetErrorType | undefined): boolean =>
  betErrorType !== undefined && betErrorType !== BetErrorType.PricesChanged

export const isFatalMultiBetLegError = (item: BetslipItem): boolean =>
  isFatalErrorType(item.multiBetLegError?.betErrorType)

export const hasErrors = (item: BetslipItem): boolean => !!item.betErrorType

export const isValidMultiInvestmentForLegs = (
  multiInvestment: MultiInvestment,
  multiItemsCount: number
): boolean =>
  Array.from(Array(Math.min(multiItemsCount, 6)).keys()).some(cur => {
    if (cur === 1) {
      return false
    }

    return !!multiInvestment[(cur === 0 ? 'value' : `f${cur}`) as keyof MultiInvestment]
  })

export const isValidMulti = (
  multiInvestment: MultiInvestment,
  multiError: MultiBetError | null,
  multiItems: immutable.List<BetslipItem>
): boolean => {
  const hasValidInvestment = isValidMultiInvestmentForLegs(multiInvestment, multiItems.count())

  return (
    hasValidInvestment &&
    !hasTooFewMultiLegs(multiItems) &&
    !hasTooManyMultiLegs(multiItems) &&
    !hasInvalidLegsOnMulti(multiItems) &&
    !multiError?.betErrorType
  )
}

export const hasNoFatalErrors = (item: BetslipItem): boolean => !isFatalErrorType(item.betErrorType)

const hasNoUnspecifiedErrors = (item: BetslipItem): boolean =>
  !isUnspecifiedErrorType(item.betErrorType)

export const isDuplicateBonusBetErrorType = (error: BetErrorType | undefined): boolean =>
  error === BetErrorType.DuplicateBonusBet

export const hasBetPlacementFault = (error: BetErrorType | undefined): boolean => {
  return error === BetErrorType.BetPlacementFault
}
export const hasNotBeenPlaced = (item: BetslipItem): boolean => !item.receipt

export const hasBeenPlaced = (item: BetslipItem): boolean => !!item.receipt

export const hasWinBoostedSuperPick = (item: BetslipItem): boolean =>
  (item &&
    !!item.selectedSuperPickOffer &&
    item.selectedSuperPickOffer.isBoostOffer &&
    item.selectedSuperPickOffer.legTypeCode === 'W') as boolean

export const hasPlaceBoostedSuperPick = (item: BetslipItem): boolean =>
  (item &&
    !!item.selectedSuperPickOffer &&
    item.selectedSuperPickOffer.isBoostOffer &&
    item.selectedSuperPickOffer.legTypeCode === 'P') as boolean

export const hasInvestment = (item: BetslipItem): boolean =>
  (!!item.investment.win && item.investment.win.value > 0) ||
  (!!item.investment.place && item.investment.place.value > 0)

export const hasTooFewMultiLegs = (multiItems: immutable.List<BetslipItem>): boolean =>
  multiItems.filter(item => item.isInMulti).count() < MIN_LEGS_IN_MULTI

export const hasTooManyMultiLegs = (multiItems: immutable.List<BetslipItem>): boolean => {
  const isRaceMulti = multiItems.some(
    item => item.isInMulti && isRaceDetails(item.selectionDetails)
  )
  return isRaceMulti
    ? multiItems.filter(item => item.isInMulti).count() > MAX_LEGS_IN_RACE_MULTI
    : multiItems.count() > MAX_LEGS_IN_SPORT_MULTI
}

export const clearNonFatalErrors = (item: BetslipItem): BetslipItem =>
  !isFatalErrorType(item.betErrorType)
    ? { ...item, errorMessage: '', betErrorType: undefined }
    : item

export const clearNonFatalMultiBetLegError = (item: BetslipItem): BetslipItem =>
  isFatalMultiBetLegError(item) ? item : { ...item, multiBetLegError: null }

export const setFobSelectionPriceSource = (item: BetslipItem): BetslipItem => {
  if (item.selection && isFobSelection(item.selection)) {
    return { ...item, selection: { ...item.selection, priceSource: 'localstorage' } }
  }
  return item
}

export const getBetsToPlace = (items: immutable.List<BetslipItem>): immutable.List<BetslipItem> =>
  items
    .filter(hasNoUnspecifiedErrors)
    .filter(x => !isDuplicateBonusBetErrorType(x.betErrorType))
    .filter(x => !hasBetPlacementFault(x.betErrorType))
    .filter(hasNoFatalErrors)
    .filter(hasNotBeenPlaced)
    .filter(hasInvestment)
    .toList()

export const getBetsInMulti = (items: immutable.List<BetslipItem>): immutable.List<BetslipItem> =>
  items.filter(item => !!item && item.isInMulti && !isToteSelection(item.selection)).toList()

export const getNewInvestmentAfterResponse = (
  item: BetslipItem,
  errorType?: BetErrorType | undefined
): BetslipInvestment => {
  const shouldResetInvestment =
    !!item.receipt || isFatalErrorType(errorType) || errorType == BetErrorType.InvalidBonusBet
  return {
    win: {
      lastKeyPressed: { mode: KeypadModes.Numeric, value: 0 },
      secondLastKeyPressed: { mode: KeypadModes.Numeric, value: 0 },
      value: shouldResetInvestment || !item.investment.win ? 0 : item.investment.win.value,
      isBonusBet: shouldResetInvestment ? false : item.investment.win.isBonusBet,
    },
    place: {
      lastKeyPressed: { mode: KeypadModes.Numeric, value: 0 },
      secondLastKeyPressed: { mode: KeypadModes.Numeric, value: 0 },
      value: shouldResetInvestment || !item.investment.place ? 0 : item.investment.place.value,
      isBonusBet: shouldResetInvestment ? false : item.investment.place.isBonusBet,
    },
    bonusBet: shouldResetInvestment ? undefined : item.investment.bonusBet,
  }
}

export const getErrorMessage = (betError: BetError | null | undefined): string =>
  betError == null
    ? ''
    : SingleErrorMapping.get(toBetErrorType(betError.type)) ||
      betError.message ||
      'Unknown error occurred'

export const getBetErrorType = (
  response:
    | {
        error: BetError | null
      }
    | undefined
): BetErrorType | undefined => {
  if (!response || !response.error) return undefined
  return toBetErrorType(response.error.type ?? BetErrorType.Unspecified)
}

export const mapMultiBetError = (
  betErrorType: BetErrorType | string | undefined
):
  | {
      betErrorType: BetErrorType
      errorMessage: string
    }
  | undefined => {
  if (betErrorType == undefined) return undefined

  var betError: BetErrorType = toBetErrorType(betErrorType)

  if (isFatalErrorType(betError)) {
    return {
      betErrorType: betError,
      errorMessage: MultiBetFatalErrorMapping.get(betError),
    }
  }
  if (betError === BetErrorType.PricesChanged) {
    return { betErrorType: BetErrorType.PricesChanged, errorMessage: '' }
  }
  return { betErrorType: BetErrorType.Unspecified, errorMessage: 'Invalid Leg' }
}

export function toBetErrorType(value: string | BetErrorType): BetErrorType {
  if (typeof value === 'string') {
    return BetErrorType[value as keyof typeof BetErrorType]
  }
  return value
}

export function getMultiBetResponse<T extends BetslipResponse>(responses: T[]): T | undefined {
  return responses?.find(res => !!res.legs && res.legs.length > 1)
}

function getInvalidMultiItems(items: immutable.List<BetslipItem>): immutable.List<BetslipItem> {
  const uniqueKeys: string[] = []
  const dupeKeys: string[] = []
  const multiItems = getBetsInMulti(items)

  const checkForDupes = (key: string) => {
    if (uniqueKeys.includes(key)) {
      if (!dupeKeys.includes(key)) {
        dupeKeys.push(key)
      }
    } else {
      uniqueKeys.push(key)
    }
  }

  multiItems.forEach(item => {
    const { selectionDetails } = item
    if (isRaceDetails(selectionDetails)) {
      checkForDupes(selectionDetails.races[0].key)
    }
    if (isFobDetails(selectionDetails)) {
      checkForDupes(selectionDetails.event)
    }
  })
  return immutable.List(
    multiItems.filter(item => {
      const { selectionDetails } = item
      if (isRaceDetails(selectionDetails)) {
        return dupeKeys.includes(selectionDetails.races[0].key)
      }
      if (isFobDetails(selectionDetails)) {
        return dupeKeys.includes(selectionDetails.event)
      }
      return false
    })
  )
}

export function isSpecialUsed(item: BetslipItem, items: immutable.List<BetslipItem>): boolean {
  if (Array.isArray(item.specialOffers) && item.specialOffers.length > 0) {
    return items.some(
      bet =>
        !!bet.selectedSuperPickOffer &&
        bet.selectedSuperPickOffer.tokenId === item.specialOffers[0].tokenId &&
        bet.id !== item.id
    )
  }
  return false
}

export function hasInvalidLegsOnMulti(items: immutable.List<BetslipItem>): boolean {
  const uniqueKeys: string[] = []
  const isDuplicateKey = (key: string) => uniqueKeys.includes(key)

  return getBetsInMulti(items).some((item): boolean => {
    const { selectionDetails } = item

    if (isRaceDetails(selectionDetails)) {
      const { key } = selectionDetails.races[0]
      if (isDuplicateKey(key)) {
        return true
      }
      uniqueKeys.push(key)
    }
    if (isFobDetails(selectionDetails)) {
      const key = selectionDetails.event
      if (isDuplicateKey(key)) {
        return true
      }
      uniqueKeys.push(key)
    }
    return false
  })
}

export function setInvalidLegOnMultiItem(
  item: BetslipItem,
  items: immutable.List<BetslipItem>
): BetslipItem {
  const invalidItems = getInvalidMultiItems(items)
  if (!invalidItems.includes(item)) {
    return item
  }
  return { ...item, multiBetLegError: { betErrorType: 0, errorMessage: 'Invalid Leg' } }
}

export function buildSelection(
  item: BetslipItem,
  ignorePriceChanges: boolean,
  responsePrices?: BetPrices
): Selection {
  const selection = item.selection

  if (isFobSelection(selection) && !isStartingPriceSelection(selection)) {
    const newSelection: FobSelection = {
      ...selection,
    }

    // * ignorePriceChanges: push prices are disgarded during bet confirmation/placement, so
    //   take price changes on propose/confirm actions (but not refresh)
    // * or, if we haven't received a push price (eg. no price change since subscription)
    //   then take this price
    const canOverwritePrices = !ignorePriceChanges || selection.priceSource !== 'push'

    if (canOverwritePrices && responsePrices) {
      if (responsePrices.placePrice) {
        newSelection.placePrice = responsePrices.placePrice
        newSelection.placePriceLastSeen = responsePrices.previousPlacePrice ?? null
      }
      if (responsePrices.winPrice) {
        newSelection.winPrice = responsePrices.winPrice
        newSelection.winPriceLastSeen = responsePrices.previousWinPrice ?? null
      }
      newSelection.priceSource = 'api'
    }
    return newSelection
  } else {
    return item.selection as Selection
  }
}

export const getMultibetErrorDescription = (
  multiBetResponse: BetslipResponse | undefined
): MultiBetError | null => {
  if (!!multiBetResponse && !multiBetResponse.success) {
    const betErrorType = getBetErrorType(multiBetResponse) || BetErrorType.Unspecified
    return {
      betErrorType: betErrorType,
      errorMessage:
        MultiErrorMapping.get(BetErrorType[betErrorType]) ||
        multiBetResponse.error?.message ||
        'An error has occurred',
    }
  } else {
    return null
  }
}
