import React from 'react'
import type { PriceChange } from '@mobi/betslip/helpers/getPriceChange'
import { MoneyGrouped } from '../../../Common/Money/MoneyGrouped'

export const MultiReturn: React.FC<{
  price: number | null
  priceChange: PriceChange
}> = ({ price, priceChange }) => {
  if (!price) return <span>N/A</span>

  return <MoneyGrouped price={price} priceChange={priceChange} />
}
