import React from 'react'
import styled from '@emotion/styled'
import { Money } from './Money'
import { PriceChange } from '@mobi/betslip/helpers/getPriceChange'

export interface MoneyGroupedProps {
  price: number
  priceChange?: PriceChange
}

export const MoneyGrouped: React.FC<MoneyGroupedProps> = ({ priceChange, price }) => (
  <MoneyGroupedStyled price={price} priceChange={priceChange} amount={price} />
)

// ======
// Styles
// ======

const priceChangeColors = {
  increase: { text: 'green' },
  decrease: { text: 'red' },
  none: {
    text: 'white',
    active: { text: 'purple' },
  },
}

const MoneyGroupedStyled = styled(Money)<MoneyGroupedProps>(
  {
    display: 'inline-block',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    margin: 0,
    position: 'relative',
    zIndex: 1,
  },

  ({ priceChange }) => {
    if (priceChange !== 'Up' && priceChange !== 'Down') {
      return undefined
    }
    return {
      marginRight: '1rem',
      color:
        priceChange === 'Up' ? priceChangeColors.increase.text : priceChangeColors.decrease.text,

      '&::after': {
        content: '""',
        position: 'absolute',
        width: 0, // Refer to link about CSS triangle usage:
        height: 0, // https://css-tricks.com/snippets/css/css-triangle/
        borderLeft: '0.5rem solid transparent',
        borderRight: '0.5rem solid transparent',
        borderBottomStyle: priceChange === 'Up' ? 'solid' : 'none',
        borderBottomWidth: priceChange === 'Up' ? '0.7rem' : 'none',
        borderTopStyle: priceChange === 'Down' ? 'solid' : 'none',
        borderTopWidth: priceChange === 'Down' ? '0.7rem' : 'none',
        transform: 'translateY(-50%)',
        top: '50%',
        right: '-1.2rem',
      },
    }
  }
)
