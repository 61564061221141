import React from 'react'
import type { FobSportsSelection, SportsDetails } from '@mobi/betslip/types'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BetCard, type LinkInfo } from '@mobi/betslip/Components/Common/BetCard'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'

export const SportBetInfo: React.FC<{
  selection: FobSportsSelection
  eventDetails: SportsDetails
}> = ({ selection, eventDetails }) => {
  const {
    sportName,
    tournamentName,
    eventName,
    marketName,
    advertisedStartTime,
    marketId,
    urlPath,
  } = eventDetails
  const { propositionName, winPrice } = selection
  const linkInfo: LinkInfo | undefined =
    marketId && urlPath ? { urlPath, market: marketId.toString() } : undefined

  return (
    <>
      <BetCard.EventDetails
        heading={`${sportName} - ${tournamentName}`}
        subHeading={eventName && eventName !== marketName ? eventName : ''}
        statusComponent={<CountdownBadge advertisedStartTime={new Date(advertisedStartTime)} />}
        iconComponent={<Icon size='2rem' type='tab' />}
        linkInfo={linkInfo}
      />

      <BetCard.SelectionInfo
        heading={propositionName}
        subHeading={marketName}
        price={`${Number(winPrice).toFixed(2)}`}
        // TODO: Use real sports/team logo
        iconComponent={
          <div
            style={{ width: '2.8rem', height: '2.8rem', borderRadius: '50%', background: 'red' }}
          />
        }
        linkInfo={linkInfo}
      />
    </>
  )
}
