import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import type { BetSlipItem } from '@mobi/betslip/types'
import {
  isFobDetails,
  isFobSportsDetails,
  isRaceDetails,
  isFobSportsSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { FobBetInfo, RaceBetInfo, SportBetInfo } from './Components'

export const BetInfo: React.FC<{
  item: BetSlipItem
}> = ({ item }) => {
  if (isFobDetails(item.selectionDetails)) {
    return <FobBetInfo item={item} />
  }

  if (isRaceDetails(item.selectionDetails)) {
    return <RaceBetInfo item={item} />
  }

  if (isFobSportsDetails(item.selectionDetails) && isFobSportsSelection(item.selection)) {
    return <SportBetInfo selection={item.selection} eventDetails={item.selectionDetails} />
  }

  return (
    // TODO: use generic notice
    <div>
      <Icon size='2rem' type='errorCircled' color='red' /> There is a problem with your bet: We were
      unable to retrieve some details
    </div>
  )
}
