import React from 'react'
import { WinFixed } from '../Common/WinFixed'

export const SportsFixed: React.FC = () => {
  return (
    <div>
      <WinFixed />
    </div>
  )
}
