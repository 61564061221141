import React, { useState } from 'react'
import styled from '@emotion/styled'
import { InfoBox, CustomerServiceCentre } from '@mobi/component-library/Common/V2'
import { isSelfServePaymentMethod } from '@mobi/component-library/Deposit/helpers'
import { PaymentMethodsAll } from '@mobi/component-library/Deposit/types'
import { Tooltip, TooltipReferenceContainer } from '@mobi/component-library/Common/V2/Tooltip'
import type { InitialData } from '@mobi/api-types'

export const DepositLimitStatus: React.FC<DepositLimitStatusProps> = ({
  depositAmount,
  depositLimits: { minimum, maximum },
  isPreCommitmentLimitSet,
  selectedPaymentMethod,
}) => {
  const [hasTooltipBeenSeen, setHasTooltipBeenSeen] = useState(true)
  const depositLimitStatusRef = React.useRef<HTMLDivElement>(null)

  if (maximum <= 0) {
    return (
      <Container>
        <InfoBox color='orange' size='md'>
          <b>Deposit Limit Reached</b>
          <div>You've reached your deposit limit for today. Please try again tomorrow.</div>
        </InfoBox>
      </Container>
    )
  }

  function getPaymentMethodMinimum(paymentMethod: PaymentMethodsAll) {
    switch (paymentMethod) {
      case 'CreditCard':
      case 'PayPal':
      case 'ApplePay':
      case 'GooglePay':
      case 'PayID':
        return minimum
      case 'BPay':
        return 20
      case 'Cash':
      case 'ScanMyBet':
        return 0
    }
  }

  const PaymentMethodMinimum = getPaymentMethodMinimum(selectedPaymentMethod)
  if (maximum < PaymentMethodMinimum) {
    return (
      <Container>
        <InfoBox color='orange' size='md'>
          <div>
            You only have ${maximum} remaining on your deposit limit for today. That is below the
            minimum deposit amount of ${PaymentMethodMinimum}. Please try again tomorrow.
          </div>
        </InfoBox>
      </Container>
    )
  }

  const showExceedsLimit =
    depositAmount > maximum && !isSelfServePaymentMethod(selectedPaymentMethod)

  if (showExceedsLimit || isPreCommitmentLimitSet) {
    const infoBoxColour = showExceedsLimit ? 'red' : 'blue'
    return (
      <Container>
        <TooltipReferenceContainer ref={depositLimitStatusRef}>
          <InfoBox color={infoBoxColour} size='sm'>
            <span>
              Your deposit limit for today is <b>${maximum}</b>.{' '}
              <a
                role='button'
                href='#'
                onClick={e => {
                  e.preventDefault()
                  setHasTooltipBeenSeen(false)
                }}
              >
                Learn more
              </a>
            </span>
          </InfoBox>
        </TooltipReferenceContainer>
        <Tooltip
          triggerElRef={depositLimitStatusRef}
          tooltipId={'deposit-uplift-primary-card'}
          hasTooltipBeenSeen={() => {
            return hasTooltipBeenSeen
          }}
          onDismissed={() => {
            setHasTooltipBeenSeen(true)
          }}
          isHidden={hasTooltipBeenSeen}
          color='black'
        >
          <span>
            <CustomerServiceCentre
              prefixText='Your remaining daily deposit limit considers any daily, weekly, fortnightly or monthly
            deposit limits you have selected for your betting account. Visit the Deposit Limits page
            to view your current settings, or'
            />
          </span>
        </Tooltip>
      </Container>
    )
  }
  return null
}

type DepositLimitStatusProps = {
  depositAmount: number
  depositLimits: InitialData['depositLimits']
  isPreCommitmentLimitSet: boolean
  selectedPaymentMethod: PaymentMethodsAll
}

export const Container = styled.div({
  paddingBottom: '2rem',
})
