import React from 'react'
import styled from '@emotion/styled'
import { colors, font, radius, shadow, spacing } from '@mobi/component-library/Theme/Common'

export const WinFixed: React.FC = () => {
  return (
    <WrapperStyled>
      <div>
        <span>Stake</span>
        <span>$0.00</span>
      </div>

      <div>
        <span>Potential Payout</span>
        <span>$0.00</span>
      </div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.sm,
  fontSize: font.size.md.fontSize,
  letterSpacing: font.size.md.letterSpacing,
  lineHeight: font.size.md.lineHeight,
  fontWeight: font.weight.regular,

  '> div': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '> span:first-of-type': {
      color: colors.black,
    },

    '> span:last-of-type': {
      width: spacing.hugex1,
      padding: spacing.sm + ' ' + spacing.md,
      textAlign: 'end',
      border: '1px solid ' + colors.neutral[300],
      borderRadius: radius.md,
      backgroundColor: colors.white,
      boxShadow: shadow.xs,
      color: colors.neutral[700],
    },
  },
})
