import styled from '@emotion/styled'

export const MultiDetailsStyled = styled.div<{ isCenter?: boolean }>(
  {
    position: 'relative',
    fontSize: '1.2rem',
    lineHeight: 'normal',
    padding: '1rem 0 0.5rem 0',
    color: 'black',
  },
  ({ isCenter = false }) => {
    if (!isCenter) return undefined

    return {
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '0.1rem',
        height: '2.6rem',
        left: '-1rem',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: '#ccc',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '0.1rem',
        height: '2.6rem',
        right: '-1rem',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: '#ccc',
      },
    }
  }
)

export const MultiDetailsAmountStyled = styled.strong({
  fontSize: '1.4rem',
  display: 'block',
  color: 'black',
})
