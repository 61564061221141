import * as immutable from 'immutable'
import { isToteSelection } from '@mobi/betslip/helpers/typeGuards'
import { BetslipItem, ReceiptItem, MultiBetError } from '../driver'
import { BetslipResponse } from '@core/Data/betslip'
import { BetErrorType } from '@core/Data/betting'
import {
  getBetErrorType,
  buildSelection,
  mapMultiBetError,
  isFatalErrorType,
  getErrorMessage,
  getNewInvestmentAfterResponse,
} from './state'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'

export function mapResponse(
  currentItem: BetslipItem,
  responses: immutable.List<BetslipResponse>,
  multiBetResponse: BetslipResponse | undefined,
  isRefreshing: boolean = false,
  ignorePriceChanges: boolean = false
): BetslipItem {
  const modifiedItem = { ...currentItem }
  const hasMultiBetResponse = !!multiBetResponse && !!multiBetResponse.legs

  if (hasMultiBetResponse) {
    const matchedLegInMultiBetResponse = ((multiBetResponse && multiBetResponse.legs) || []).find(
      leg => leg.id === modifiedItem.id
    )

    if (matchedLegInMultiBetResponse) {
      const matchedSingleInResponse = responses.find(
        res => res.id === matchedLegInMultiBetResponse.id
      )
      const matchedSingleInResponseError = getBetErrorType(matchedSingleInResponse)
      const matchedLegInMultiBetErrorType = getBetErrorType(matchedLegInMultiBetResponse)

      const newSelection = buildSelection(
        modifiedItem,
        ignorePriceChanges,
        matchedLegInMultiBetResponse.prices
      )

      const newMultiBetLegError: MultiBetError | undefined = mapMultiBetError(
        matchedLegInMultiBetErrorType
      )
      const hasFatalError: boolean =
        isFatalErrorType(matchedLegInMultiBetErrorType) ||
        isFatalErrorType(matchedSingleInResponseError) ||
        isFatalErrorType(newMultiBetLegError?.betErrorType)

      modifiedItem.selection = newSelection
      modifiedItem.multiBetLegError = newMultiBetLegError || null
      modifiedItem.isInMulti = hasFatalError ? false : modifiedItem.isInMulti

      if (hasFatalError) {
        modifiedItem.betErrorType = matchedLegInMultiBetErrorType
        modifiedItem.errorMessage = matchedLegInMultiBetResponse?.error?.message || ''
      }
    }
  }

  const response = responses.find(res => res.id === modifiedItem.id)
  if (!response) {
    return modifiedItem
  }

  const responseLeg = response && response.legs && response.legs[0]

  modifiedItem.selection = buildSelection(
    modifiedItem,
    ignorePriceChanges,
    responseLeg && responseLeg.prices
  )

  modifiedItem.isSuperPickAvailable =
    modifiedItem.specialOffers && modifiedItem.specialOffers.length > 0

  modifiedItem.specialOffers = updateSpecialOffersFromResponse(
    modifiedItem,
    response,
    isRefreshing
  ) as BetSpecialOffer[]

  modifiedItem.selectedSuperPickOffer =
    (modifiedItem.selectedSuperPickOffer &&
      modifiedItem.specialOffers &&
      modifiedItem.specialOffers.find(
        s =>
          s.specialSeq ===
          (modifiedItem.selectedSuperPickOffer && modifiedItem.selectedSuperPickOffer.specialSeq)
      )) ||
    null

  if (!response.success) {
    const betErrorType = getBetErrorType(response)
    const shouldClearSpecials = betErrorType && betErrorType === BetErrorType.SpecialsError

    modifiedItem.betErrorType = betErrorType
    modifiedItem.errorMessage = getErrorMessage(response.error)
    modifiedItem.investment = getNewInvestmentAfterResponse(modifiedItem, betErrorType)
    modifiedItem.selectedSuperPickOffer = shouldClearSpecials
      ? null
      : modifiedItem.selectedSuperPickOffer
    modifiedItem.isSuperPickAvailable = shouldClearSpecials ? false : true

    return modifiedItem
  }

  // Clear existing errors on successful response
  modifiedItem.errorMessage = ''
  modifiedItem.betErrorType = undefined

  // Check for receipt
  const receiptItem = (response as BetslipResponse).receipt as ReceiptItem

  if (receiptItem) {
    const specialOffers = !isToteSelection(modifiedItem.selection)
      ? receiptItem.specialOffers
      : null

    modifiedItem.investment = getNewInvestmentAfterResponse(modifiedItem)
    modifiedItem.receipt = specialOffers ? { ...receiptItem, specialOffers } : receiptItem
  }

  return modifiedItem
}

function updateSpecialOffersFromResponse(
  modifiedItem: BetslipItem,
  response: BetslipResponse,
  isRefreshing?: boolean
) {
  // Refresh will update all specials
  if (isRefreshing) {
    return response.specialOffers || []
  }

  // Propose/Commit will only update selected specials
  const hasSelectedSpecialOfferOnResponse =
    modifiedItem.selectedSuperPickOffer && response.specialOffers && response.specialOffers[0]

  if (hasSelectedSpecialOfferOnResponse) {
    return modifiedItem.specialOffers.map(specialOffer =>
      specialOffer.specialSeq === (response.specialOffers && response.specialOffers[0].specialSeq)
        ? response.specialOffers && response.specialOffers[0]
        : specialOffer
    )
  }
  return modifiedItem.specialOffers
}
