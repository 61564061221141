import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { colors } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { ShareButton } from '@mobi/betslip/Components/Common/BetCard/Components/Footer'
import type { AllUpSelection, BetSlipItem } from '@mobi/betslip/types'
import { removeItemFromBetSlipById } from '@mobi/betslip/Store/Bets'
import { BetInfo } from '@mobi/betslip/Components/Common/BetInfo/BetInfo'
import { isAllUpSelection } from '@mobi/betslip/helpers/typeGuards'
import { isFatalErrorType, isNonHandledError } from '@mobi/betslip/helpers/state'
import { BetInvestment } from './Components/BetInvestment/BetInvestment'
import { SingleAllUpFormula } from './Components/SingleAllUpFormula/SingleAllUpFormula'

export const SingleItem: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const dispatch = useDispatch()

  // TODO: Use real redux state
  const { hasProposed, isBusy } = { hasProposed: false, isBusy: false }

  const isAllUp = isAllUpSelection(item.selection)
  const isNotDefaultAllUp =
    isAllUp &&
    (item.selection as AllUpSelection).formulas.slice(0, -1).some(formula => formula.isSelected)
  const allUpTotalCost = isAllUp ? item.investment.win.value * (item.numberOfCombinations || 0) : 0
  const hasFatalError = isFatalErrorType(item.betErrorType) || false
  const isEditable = !hasProposed && !isBusy

  const handleRemove = () => dispatch(removeItemFromBetSlipById(item.id))
  // TODO: TBD in future work
  const handleShareClick = () => null

  return (
    <BetCard.Container key={item.id}>
      <BetInfo item={item} />

      {/* {hasFatalError && <pre>Display error styles</pre>} */}

      {isNonHandledError(item.betErrorType) && (
        // TODO: use generic component notice
        <div>Error: {item.errorMessage}</div>
      )}

      {!hasFatalError && <BetInvestment item={item} isEditable={isEditable} />}

      {isAllUp && isNotDefaultAllUp && (
        <SingleAllUpFormula selection={item.selection as AllUpSelection} />
      )}

      {isAllUp && allUpTotalCost > 0 && !item.receipt && (
        <TotalCostStyled>
          Total Cost
          <MoneyStyled>${allUpTotalCost.toFixed(2)}</MoneyStyled>
        </TotalCostStyled>
      )}

      <BetCard.Footer
        rightSection={
          <>
            <ShareButton onClick={handleShareClick} />

            <Button
              size='xs'
              color='tertiary_grey'
              isIconOnlyButton
              onClick={handleRemove}
              disabled={!isEditable}
            >
              <Icon name='LineTrash01' size='1.6rem' color={colors.black} />
            </Button>
          </>
        }
      />
    </BetCard.Container>
  )
}

// ======
// Styles
// ======

const TotalCostStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '2.7rem',
  fontSize: '1.2rem',
  color: 'black',
})

const MoneyStyled = styled.span({
  fontSize: '1.6rem',
  fontWeight: 'bold',
  color: 'black',
})
