import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'

const enum LocalConstants {
  ActiveIndicatorWidthPx = 1000,
  TabButtonActiveClass = 'js-tabs__tab-button--active',
}

/** Main wrapping component to be used with <TabButton /> children */
export const Tabs: React.FC = ({ children }) => {
  const scrollContainerRef = React.useRef<HTMLUListElement>(null)
  const activeIndicatorRef = React.useRef<HTMLSpanElement>(null)

  React.useEffect(() => {
    if (!activeIndicatorRef.current) return
    activeIndicatorRef.current.style.transition = 'transform 0.2s'
  }, [])

  return (
    <TabsWrapperStyled data-testid='TabSelector'>
      <RefSharingContext.Provider value={{ activeIndicatorRef, scrollContainerRef }}>
        <ul ref={scrollContainerRef}>
          {children}

          <span aria-hidden ref={activeIndicatorRef} />
        </ul>
      </RefSharingContext.Provider>
    </TabsWrapperStyled>
  )
}

/** Requires wrapping <Tabs /> parent component */
export const TabButton: React.FC<{
  name: string
  isSelected: boolean
  onClick: () => void
}> = ({ name, isSelected, onClick }) => {
  const buttonContainerRef = React.useRef<HTMLLIElement>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const { activeIndicatorRef, scrollContainerRef } = React.useContext(RefSharingContext)

  const repositionActiveIndicator = React.useCallback(() => {
    if (!buttonRef.current || !activeIndicatorRef?.current) return
    const percentageDiff = buttonRef.current.offsetWidth / LocalConstants.ActiveIndicatorWidthPx
    activeIndicatorRef.current.style.transform = `translateX(${buttonRef.current.offsetLeft}px) scaleX(${percentageDiff})`
  }, [activeIndicatorRef])

  // On isSelected, set position of active indicator and scroll tab into view
  React.useEffect(() => {
    if (!isSelected || !buttonContainerRef.current || !scrollContainerRef?.current) return
    repositionActiveIndicator()
    scrollContainerRef.current.scrollLeft =
      buttonContainerRef.current.offsetLeft - buttonContainerRef.current.offsetWidth
  }, [isSelected, repositionActiveIndicator, scrollContainerRef])

  // Respond to changes in document size to resposition active indicator
  React.useEffect(() => {
    if (!window.ResizeObserver || !isSelected) return
    const observer = new ResizeObserver(repositionActiveIndicator)
    observer.observe(document.body)
    return () => observer.disconnect()
  }, [isSelected, repositionActiveIndicator])

  return (
    <TabButtonWrapperStyled ref={buttonContainerRef}>
      <button
        ref={buttonRef}
        className={`${isSelected ? LocalConstants.TabButtonActiveClass : ''}`}
        onClick={onClick}
      >
        <span>{name}</span>

        {/* Duplicate of above used to prevent font weight jump on active transition */}
        <span aria-hidden>{name}</span>
      </button>
    </TabButtonWrapperStyled>
  )
}

// =============
// Local Helpers
// =============

const RefSharingContext = React.createContext<{
  activeIndicatorRef?: React.RefObject<HTMLSpanElement>
  scrollContainerRef?: React.RefObject<HTMLUListElement>
}>({})

// ======
// Styles
// ======

const TabsWrapperStyled = styled.div({
  overflow: 'hidden',

  '*': { boxSizing: 'border-box' },

  '> ul': {
    position: 'relative',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    borderSpacing: 0,
    width: '100%',
    minHeight: '4.8rem',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    background: colors.white,
    borderBottom: `0.05rem solid ${colors.neutral[200]}`,

    overflowX: 'auto',
    overscrollBehavior: 'contain',
    scrollBehavior: 'smooth',
    WebkitOverflowScrolling: 'touch',
    WebkitTapHighlightColor: 'transparent',
    scrollbarWidth: 'thin',
    scrollbarColor: colors.neutral[300] + ' ' + colors.surface[50],

    '@media (pointer: coarse)': {
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': { display: 'none' },
    },
  },

  // Active Indicator
  '> ul > span': {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: LocalConstants.ActiveIndicatorWidthPx + 'px',
    height: '0.2rem',
    backgroundColor: colors.studio[500],

    transform: 'scaleX(0)',
    transformOrigin: 'left',
  },
})

const TabButtonWrapperStyled = styled.li({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: spacing.smx1,
  paddingRight: spacing.smx1,

  '&:first-of-type': { marginLeft: spacing.smx1 },
  '&:last-of-type': { marginRight: spacing.smx1 },

  '> button': {
    flexShrink: 0,
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing.smx2,
    minHeight: '4.8rem',
    padding: 0,
    whiteSpace: 'nowrap',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    border: 0,
    backgroundColor: 'transparent',
    width: '100%',
    cursor: 'pointer',

    fontFamily: font.family.primary,
    fontSize: font.size.md.fontSize,
    lineHeight: font.size.md.lineHeight,
    letterSpacing: font.size.md.letterSpacing,
    fontWeight: font.weight.regular,
    color: colors.neutral[800],

    '> span:first-of-type': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',

      transition: 'color 0.3s',
    },

    [`&.${LocalConstants.TabButtonActiveClass} > span:first-of-type`]: {
      color: colors.black,
      fontWeight: font.weight.medium,
    },

    '> span > span': {
      fontSize: 0,
      lineHeight: 1,
      paddingRight: spacing.smx2,
    },

    // Used to prevent font weight jump on active state
    '> span:last-of-type': {
      visibility: 'hidden',
      fontWeight: font.weight.medium,
    },
  },
})
