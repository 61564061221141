import { attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'
import { isFobMatchedSelection, isFobPropositionSelection } from '@mobi/betslip/helpers/typeGuards'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import { getSuperPicks, GetSuperPicksRequest, SuperPicksResponse } from '@core/Data/betting'
import { isStartingPriceMatchedSelection } from '@core/Data/Betting/selections'
import {
  QuickbetLoadSelection,
  QuickbetSelection,
  EditBetslipItem,
} from '@core/Areas/Quickbet/signals'
import { BetslipItem } from '@core/Areas/Betslip/driver'
import {
  LoadSuperPicks,
  ChangeSelectedSuperPick,
  GetSuperPicksSucceeded,
  GetSuperPicksFailed,
  SetInvalidSuperPickSelection,
  ResetInvalidSuperPickSelection,
  UpdateSelectedSuperPick,
} from './signals'

export interface SuperPickState {
  fixtureDate?: string
  fixtureId?: string
  raceNumber?: number
  acceptorNumber?: number
  propositionSeq: string | null
  specialOffers: BetSpecialOffer[]
  selectedSuperPickSeq?: number | null
  invalidSelectionMessage?: string | null
  isSuperPickLoaded: boolean
  isSuperBoostAvailable: boolean
}

export const defaultSuperpickState: SuperPickState = {
  fixtureDate: undefined,
  fixtureId: undefined,
  raceNumber: undefined,
  acceptorNumber: undefined,
  propositionSeq: null,
  selectedSuperPickSeq: null,
  specialOffers: [],
  invalidSelectionMessage: null,
  isSuperPickLoaded: false,
  isSuperBoostAvailable: false,
}

export interface SuperPickStateRecord extends TypedRecord<SuperPickStateRecord>, SuperPickState {}
export const SuperpickStateFactory = makeTypedFactory<SuperPickState, SuperPickStateRecord>(
  defaultSuperpickState
)

export function superPickDriver(
  state = SuperpickStateFactory(),
  signal: Signal
): SuperPickStateRecord {
  switch (signal.tag) {
    case QuickbetLoadSelection: {
      if (signal.data) {
        const payload = signal.data as QuickbetSelection
        if (
          isFobMatchedSelection(payload.selection) ||
          isStartingPriceMatchedSelection(payload.selection)
        ) {
          const acceptorNumber = isFobMatchedSelection(payload.selection)
            ? payload.selection.acceptorNumber
            : payload.selection.selectedAcceptor?.number

          return state.merge({
            ...defaultSuperpickState,
            fixtureId: payload.selection.fixtureId,
            fixtureDate: payload.selection.fixtureDate,
            raceNumber: payload.selection.raceNumber,
            acceptorNumber,
          })
        }
        if (isFobPropositionSelection(payload.selection)) {
          return state.merge({
            ...defaultSuperpickState,
            propositionSeq: payload.selection.propositionSeq,
          })
        }
      }
      return state.merge({
        ...defaultSuperpickState,
      })
    }

    case EditBetslipItem: {
      if (!signal.data) {
        return state.merge({
          ...defaultSuperpickState,
        })
      }

      const payload = signal.data as BetslipItem
      const selectedSuperPickSeq = payload.selectedSuperPickOffer
        ? payload.selectedSuperPickOffer.specialSeq
        : null

      if (isFobMatchedSelection(payload.selection)) {
        return state.merge({
          ...defaultSuperpickState,
          fixtureId: payload.selection.fixtureId,
          fixtureDate: payload.selection.fixtureDate,
          raceNumber: payload.selection.raceNumber,
          acceptorNumber: payload.selection.acceptorNumber,
          selectedSuperPickSeq,
        })
      }

      if (isFobPropositionSelection(payload.selection)) {
        return state.merge({
          ...defaultSuperpickState,
          propositionSeq: payload.selection.propositionSeq,
          selectedSuperPickSeq,
        })
      }

      return state.merge({
        ...defaultSuperpickState,
        selectedSuperPickSeq,
      })
    }

    case LoadSuperPicks: {
      const currentState: SuperPickStateRecord = { ...state.toJS() }

      const request: GetSuperPicksRequest = {
        FixtureDate: currentState.fixtureDate,
        FixtureId: currentState.fixtureId,
        RaceNumber: currentState.raceNumber,
        StarterNumber: currentState.acceptorNumber,
        PropositionSeq: currentState.propositionSeq || undefined,
      }

      getSuperPicks(request)
        .then(response => GetSuperPicksSucceeded(response))
        .catch(error => GetSuperPicksFailed(error))

      return state.merge({
        isSuperPickLoaded: false,
      })
    }

    case GetSuperPicksSucceeded: {
      let specialOffers: BetSpecialOffer[] = []
      let isSuperBoostAvailable = false
      if (signal.data.specialOffers) {
        specialOffers = (signal.data as SuperPicksResponse).specialOffers
          .map(so => BetSpecialOffer.normalise(so))
          .sort((a: BetSpecialOffer, b: BetSpecialOffer) => {
            if (a.isSuperBoost) {
              return -1
            }

            if (b.isSuperBoost) {
              return 1
            }

            return 0
          })
        isSuperBoostAvailable = !!specialOffers.find(so => so.isSuperBoost)
      }
      return state.merge({
        specialOffers,
        isSuperPickLoaded: true,
        isSuperBoostAvailable,
      })
    }

    case UpdateSelectedSuperPick: {
      if (!signal.data.specialOffers) {
        return state
      }

      const selectedOffer: BetSpecialOffer = BetSpecialOffer.normalise(signal.data.specialOffers[0])

      const existingOffers = (state.toJS() as SuperPickState).specialOffers
      const index = existingOffers.findIndex(offer => offer.specialSeq === selectedOffer.specialSeq)
      if (index !== -1) {
        existingOffers.splice(index, 1, selectedOffer)
      }

      return state.merge({
        specialOffers: existingOffers,
        isSuperPickLoaded: true,
      })
    }
    case GetSuperPicksFailed: {
      return state
    }

    case ChangeSelectedSuperPick: {
      if (state.selectedSuperPickSeq === signal.data) {
        return state.merge({
          selectedSuperPickSeq: null,
          invalidSelectionMessage: null,
        })
      }
      return state.merge({
        selectedSuperPickSeq: signal.data,
        invalidSelectionMessage: null,
      })
    }

    case SetInvalidSuperPickSelection: {
      return state.merge({
        invalidSelectionMessage: signal.data,
      })
    }

    case ResetInvalidSuperPickSelection: {
      return state.merge({
        invalidSelectionMessage: null,
      })
    }

    default:
      return state
  }
}

export const state$ = attachDriver<SuperPickStateRecord>({
  path: 'superpick',
  driver: superPickDriver,
})
