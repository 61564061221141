import React from 'react'
import styled from '@emotion/styled'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'

export const Container: React.FC = ({ children }) => {
  return <CardStyled>{children}</CardStyled>
}

const CardStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.sm,
  padding: spacing.sm,
  fontFamily: font.family.primary,
  backgroundColor: colors.white,
  border: '1px solid ' + colors.neutral[200],
  borderRadius: radius.lgx1,
})
