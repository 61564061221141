import React, { type CSSProperties, type ReactNode } from 'react'
import styled from '@emotion/styled'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { useRenderTimeoutControl } from '@mobi/utils/hooks/useRenderTimeoutControl'
import {
  colors,
  font,
  radius,
  spacing,
  layering,
  shadow,
  measurements,
} from '@mobi/component-library/Theme/Common'
import { PillButton } from '@mobi/component-library/Common/V2/Buttons/PillButton'
import { Icon } from '@mobi/component-library/Common/V2'

const enum LocalConstants {
  AnimationTimingMs = 400,
  DimmerClassName = 'js-tray-dimmer',
  TrayFullscreenClassName = 'js-tray--fullscreen',
  TrayUnmountClassName = 'js-tray--unmounting',
}

type Props = {
  /** Whether the tray is open and should be rendered */
  isOpen: boolean
  /** The header title of the tray */
  headerTitle: string
  /** Whether the header should be visible */
  shouldShowHeader?: boolean
  /**
   * Whether the tray should cover most of the screen
   *
   * If not, the height will match the contents of the tray
   */
  shouldCoverFullscreen?: boolean
  /**
   * Whether extra padding is needed because of how the bottom navigation is rendered
   *
   * On web, the navigation is rendered within the app. On native, it's rendered outside of it
   */
  requiresExtraBottomPadding?: boolean
  /** The children to render */
  children?: ReactNode
  /**
   * How the close button and backdrop behave
   *
   * - allowed - Clicking the button or backdrop dismisses the tray (default)
   * - disallowed - The button is hidden. Clicking the backdrop does nothing
   * - disabled - The button is visible, but disabled. Clicking the backdrop does nothing
   */
  dismissStatus?: 'allowed' | 'disallowed' | 'disabled'
  /** Callback when the user has dismissed the modal */
  onDismiss?: () => void
  /**
   * Overrides for the tray content styling
   *
   * These styles get placed in the `style` attribute of the <article> element
   */
  trayStyle?: Partial<CSSProperties>
}

export const Tray: React.FC<Props> = ({
  isOpen,
  headerTitle,
  shouldShowHeader = true,
  onDismiss,
  dismissStatus = 'allowed',
  shouldCoverFullscreen = false,
  children,
  requiresExtraBottomPadding = true,
  trayStyle,
}) => {
  const shouldRenderTray = useRenderTimeoutControl({
    shouldRender: isOpen,
    timeoutMs: LocalConstants.AnimationTimingMs as number,
  })
  const scrollContainerRef = useBodyScrollLock(shouldRenderTray)

  if (!shouldRenderTray) return null

  const wrapperClassNames: string[] = []
  if (!isOpen) wrapperClassNames.push(LocalConstants.TrayUnmountClassName)
  if (shouldCoverFullscreen) wrapperClassNames.push(LocalConstants.TrayFullscreenClassName)

  const canBeDismissed = dismissStatus === 'allowed'
  const isCloseButtonVisible = dismissStatus !== 'disallowed'

  return (
    <>
      <WrapperStyled
        role='dialog'
        aria-labelledby='tray-title'
        aria-modal='true'
        data-testid='Overlay.Tray'
        className={wrapperClassNames.join(' ')}
        requiresExtraBottomPadding={requiresExtraBottomPadding}
      >
        <article style={trayStyle}>
          <header>
            <h2
              id='tray-title'
              data-testid='Overlay.Tray.Title'
              style={{ visibility: shouldShowHeader ? 'visible' : 'hidden' }}
            >
              {headerTitle}
            </h2>

            {isCloseButtonVisible && (
              <PillButton
                color='secondary_grey'
                isIconOnlyButton
                onClick={onDismiss}
                data-testid='Overlay.Tray.Close'
                aria-label='Close tray'
                disabled={!canBeDismissed}
              >
                <Icon name='SolidXClose' size='2rem' />
              </PillButton>
            )}
          </header>

          <div ref={scrollContainerRef} data-testid='Overlay.Tray.Content'>
            {children}
          </div>

          {/* TODO: Always visible "Bottom Container" for actions (as described in Figma)
            <footer></footer>
          */}
        </article>
      </WrapperStyled>

      <div
        role={canBeDismissed ? 'button' : 'presentation'}
        aria-label='Tray backdrop'
        className={LocalConstants.DimmerClassName as string}
        onClick={canBeDismissed ? onDismiss : undefined}
      />
    </>
  )
}

type WrapperProps = Required<Pick<Props, 'requiresExtraBottomPadding'>>

const WrapperStyled = styled.div<WrapperProps>(({ requiresExtraBottomPadding }) => ({
  position: 'fixed',
  zIndex: layering.overlayHigh,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  overflow: 'hidden',
  pointerEvents: 'none',

  '> article': {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
    width: '100%',
    minWidth: measurements.mobi.minWidth,
    maxWidth: measurements.mobi.maxWidth,
    padding: `${spacing.md} ${spacing.md} 0`,
    margin: '0 auto',
    pointerEvents: 'all',
    touchAction: 'manipulation',
    background: colors.white,
    borderRadius: `${radius.lgx4} ${radius.lgx4} 0 0`,
    boxShadow: shadow.lg,

    animationName: 'trayIn',
    animationFillMode: 'forwards',
    animationDuration: LocalConstants.AnimationTimingMs + 'ms',
    animationTimingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',

    '> header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: spacing.md,

      '> h2': {
        padding: 0,
        margin: 0,
        background: 0,
        color: colors.black,
        fontFamily: font.family.primary,
        fontSize: font.size.xl2.fontSize,
        letterSpacing: font.size.xl2.letterSpacing,
        lineHeight: font.size.xl2.lineHeight,
        fontWeight: font.weight.semibold,
        textShadow: 'none',
        textTransform: 'unset',
      },
    },

    // Children container
    '> div': {
      flex: 1,
      overflow: 'auto',
      paddingBottom: requiresExtraBottomPadding ? spacing.lg : 0,
    },
  },

  [`& + .${LocalConstants.DimmerClassName}`]: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    animation: `fadeIn ${LocalConstants.AnimationTimingMs}ms ease forwards`,
  },

  [`&.${LocalConstants.TrayFullscreenClassName}`]: {
    '> article': { minHeight: '90vh' },
  },

  [`&.${LocalConstants.TrayUnmountClassName}`]: {
    '> article': {
      animationTimingFunction: 'cubic-bezier(0.550, 0.085, 0.680, 0.530)',
      animationName: 'trayOut',
    },

    [`+ .${LocalConstants.DimmerClassName}`]: {
      animation: `fadeOut ${LocalConstants.AnimationTimingMs}ms ease forwards`,
    },
  },

  '@keyframes fadeIn': { from: { opacity: 0 }, to: { opacity: 1 } },
  '@keyframes fadeOut': { from: { opacity: 1 }, to: { opacity: 0 } },
  '@keyframes trayIn': {
    from: { transform: 'translateY(100%)' },
    to: { transform: 'translateY(0)' },
  },
  '@keyframes trayOut': {
    from: { transform: 'translateY(0)' },
    to: { transform: 'translateY(105%)' },
  },
}))
