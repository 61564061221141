import React from 'react'
import styled from '@emotion/styled'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import type { LinkInfo } from '../../types'
import { LinkWrapper } from '../Common/LinkWrapper'

// TODO: Consider renaming to BetSelection (as used in Figma)

export const SelectionInfo: React.FC<{
  heading: string
  subHeading: string
  price?: string
  iconComponent?: React.ReactNode
  rightAlignedComponent?: React.ReactNode
  linkInfo?: LinkInfo
}> = ({ heading, subHeading, price, iconComponent, rightAlignedComponent, linkInfo }) => {
  return (
    <LinkWrapper linkInfo={linkInfo} shouldProvideMarket>
      <WrapperStyled>
        <div>
          {iconComponent && <span>{iconComponent}</span>}

          <h3>
            <span>{heading}</span>
            <span>{subHeading}</span>
          </h3>
        </div>

        <div>{price}</div>

        <div>{rightAlignedComponent}</div>
      </WrapperStyled>
    </LinkWrapper>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.sm,
  padding: spacing.smx1,
  borderRadius: radius.md,
  backgroundColor: colors.surface[100],

  // Selection text info + icon
  '> div:nth-of-type(1)': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',

    '> span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '3.6rem',
      height: '3.6rem',
      marginRight: spacing.sm,
      borderRadius: radius.smx1,
      backgroundColor: colors.surface[400],
    },

    '> h3': {
      display: 'flex',
      flexDirection: 'column',
      background: 0,
      padding: 0,
      margin: 0,
      textTransform: 'none',

      '> span:first-of-type': {
        fontSize: font.size.lg.fontSize,
        fontWeight: font.weight.medium,
        letterSpacing: font.size.lg.letterSpacing,
        lineHeight: font.size.lg.lineHeight,
        color: colors.black,
      },

      '> span:last-of-type': {
        fontSize: font.size.md.fontSize,
        fontWeight: font.weight.regular,
        letterSpacing: font.size.md.letterSpacing,
        lineHeight: font.size.md.lineHeight,
        color: colors.neutral[800],
      },
    },
  },

  // Price
  '> div:nth-of-type(2)': {
    marginLeft: 'auto',
    fontSize: font.size.lg.fontSize,
    fontWeight: font.weight.medium,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,
    color: colors.black,
  },
})
