import React from 'react'
import styled from '@emotion/styled'
import { Accordion } from '@mobi/component-library/Common/V2/Accordion'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import type { BetSlipBetsState, BetSlipItem, MultiInvestment } from '@mobi/betslip/types'
import type { ReceiptItem } from '@mobi/betslip/types/todo'
import { PriceChange, getPriceChange } from '@mobi/betslip/helpers/getPriceChange'
import { BetInvestmentGroupStyled } from '../Common/BetInvestment.styles'
import { InvestmentTextStyled, InvestmentStyled } from '../Common/BetInvestment.styles'
import { MultiReturn } from './Components/MultiReturn/MultiReturn'
import { MIN_LEGS_IN_MULTI } from '../../helpers/constants'
import {
  calculateMultiProjectedPay,
  calclulateCombinedMultiInvestment,
  calculateMultiReturn,
  calculateLastSeenMultiReturn,
} from '../../helpers/calculator'
import { MultiDetailsStyled, MultiDetailsAmountStyled } from './Multi.styles'
import { Money } from '../Common/Money/Money'
import { MultiFormula } from './Components/MultiFormula/MultiFormula'
import { MultiItem } from './Components/MultiItem/MultiItem'
import {
  getBetsInMulti,
  hasTooFewMultiLegs,
  hasTooManyMultiLegs,
  isValidMulti,
  isValidMultiInvestmentForLegs,
} from '../../helpers/state'

interface ItemListStateProps {
  items: BetSlipItem[]
  disabled: boolean
  receipt?: ReceiptItem | null
  multiBetError: BetSlipBetsState['multiBetError'] | null
  multiInvestment: MultiInvestment
}

const handleExpandablePanelClick = () => {
  // ToggleBetslipExpandable('Multi')
}

export const Multi = () => {
  // TODO: Use redux
  const { multiReceipt, hasProposed, isBusy } = {} as BetSlipBetsState
  const { items, receipt, disabled, multiBetError, multiInvestment } = {
    // TODO: items filter
    // .filter(
    //   item => !isToteSelection(item.selection) && !isStartingPriceSelection(item.selection)
    // )
    items: [],
    receipt: multiReceipt,
    disabled: hasProposed || isBusy || multiReceipt != null,
    multiBetError: null,
    multiInvestment: {} as BetSlipBetsState['multiInvestment'],
  } as ItemListStateProps

  const anyBetsPlaced = items.some(x => !!x.receipt) || !!receipt
  const selectedMultiItems = getBetsInMulti(items)

  const isMultiValid = isValidMulti(multiInvestment, multiBetError, selectedMultiItems)
  const isMultiInvestmentValid = isValidMultiInvestmentForLegs(
    multiInvestment,
    selectedMultiItems.length
  )

  if (hasProposed && !isMultiValid && !multiBetError) return null

  const hasEnoughBets = items.length >= MIN_LEGS_IN_MULTI
  const hasEnoughSelections = selectedMultiItems.length >= MIN_LEGS_IN_MULTI
  const hasTooManyLegs = hasTooManyMultiLegs(selectedMultiItems)
  const showBetTypeToggle = !anyBetsPlaced && !hasProposed

  const multiTotalCost = calclulateCombinedMultiInvestment(selectedMultiItems, multiInvestment)
  const multiReturn = calculateMultiReturn(items, { shouldRound: true })
  const multiReturnLastSeen = calculateLastSeenMultiReturn(items, { shouldRound: true })
  const multiReturnPriceChange: PriceChange = multiReturnLastSeen
    ? getPriceChange(multiReturnLastSeen, multiReturn)
    : 'NoChange'

  const isValidNumberOfLegs = !hasTooFewMultiLegs(selectedMultiItems) && !hasTooManyLegs

  const handleInvestmentClick = () => {
    // SetQuickEditInvestment(multiInvestment.value)
    // ToggleBonusCash(multiInvestment.isBonusCash ?? true)
    // SetBonusBet(
    //   multiInvestment.bonusBetId
    //     ? { campaignId: multiInvestment.bonusBetId, value: multiInvestment.value }
    //     : null
    // )
    // SetQuickEditValue(multiInvestment.value)
    // dispatch(
    //   openModal({
    //     id: QUICK_EDIT_MODAL,
    //     modalComponent: QuickEdit,
    //     modalComponentProps: {
    //       title: 'Multi Bet',
    //       subTitle: `${selectedMultiItems.count()} Legs`,
    //     },
    //     color: 'dark',
    //   })
    // )
  }

  return (
    <Accordion
      title={`Multi (${hasEnoughBets ? 1 : 0})`}
      onToggle={handleExpandablePanelClick}
      shouldStartExpanded={true}
      shouldUseDefaultTheme={false}
    >
      {!hasEnoughBets && <EmptyItemStyled>Add more bets to create a Multi</EmptyItemStyled>}

      {hasEnoughBets && (
        <MultiSectionStyled>
          {!hasEnoughSelections && (
            <div>Error: {`${MIN_LEGS_IN_MULTI} legs minimum for Multi`}</div>
          )}

          {!multiBetError?.errorMessage && hasTooManyLegs && (
            <>
              <div>
                Error: Exceeded number of allowed legs for Multi bet. Please amend your bet.
              </div>
            </>
          )}

          {multiBetError?.errorMessage && (
            <div>
              Error:{' '}
              {multiBetError.errorMessage.indexOf('Multibet') > -1 &&
              multiBetError.errorMessage.indexOf('must contain no more than') > -1
                ? 'Exceeded number of allowed legs for Multi bet. Please amend your bet.'
                : multiBetError.errorMessage}
            </div>
          )}

          {multiInvestment.f1 > 0 && !isMultiInvestmentValid && (
            <div>
              Warning: Invalid formula investment, &quot;Singles only&quot; is not a valid bet type.
            </div>
          )}

          {receipt ? null : (
            <>
              <MultiItemListStyled>
                {items.map(item => (
                  <MultiItem
                    key={item.id || ''}
                    item={item}
                    disabled={disabled}
                    showBetTypeToggle={showBetTypeToggle}
                  />
                ))}
              </MultiItemListStyled>

              <MultiSpendStyled
                isEditable={!disabled && isValidNumberOfLegs}
                onClick={!disabled && isValidNumberOfLegs ? handleInvestmentClick : undefined}
                role='button'
              >
                <InvestmentTextStyled isEditable={!disabled && isValidNumberOfLegs}>
                  Spend
                </InvestmentTextStyled>

                <InvestmentStyled
                  price={multiInvestment.value}
                  priceChange='NoChange'
                  isEditable={!disabled && isValidNumberOfLegs}
                  isBonusBet={multiInvestment.isBonusBet}
                />
              </MultiSpendStyled>

              <Grid>
                <GridCell align='left' valign='middle'>
                  <MultiDetailsStyled>
                    Multi Rtn
                    <MultiDetailsAmountStyled>
                      <MultiReturn price={multiReturn} priceChange={multiReturnPriceChange} />
                    </MultiDetailsAmountStyled>
                  </MultiDetailsStyled>
                </GridCell>

                <GridCell align='center' valign='middle' width='7rem'>
                  <MultiDetailsStyled isCenter={true}>
                    No. Legs
                    <MultiDetailsAmountStyled>{selectedMultiItems.length}</MultiDetailsAmountStyled>
                  </MultiDetailsStyled>
                </GridCell>

                <GridCell align='right' valign='middle'>
                  <MultiDetailsStyled>
                    Proj. Pay
                    <MultiDetailsAmountStyled>
                      <Money
                        amount={calculateMultiProjectedPay(selectedMultiItems, multiInvestment)}
                      />
                    </MultiDetailsAmountStyled>
                  </MultiDetailsStyled>
                </GridCell>
              </Grid>

              <MultiFormula />

              {multiTotalCost > 0 && (
                <MultiTotalContainerStyled>
                  Total Multi Cost
                  <MultiTotalAmountStyled>${multiTotalCost.toFixed(2)}</MultiTotalAmountStyled>
                </MultiTotalContainerStyled>
              )}
            </>
          )}
        </MultiSectionStyled>
      )}
    </Accordion>
  )
}

// ======
// Styles
// ======

const EmptyItemStyled = styled.div({
  marginTop: '1.4rem',
  fontSize: '1.4rem',
  textAlign: 'center',
  color: '#aaa',
})

const MultiSectionStyled = styled.div({
  margin: '0.5rem',
  // padding: BETSLIP_ITEM_PADDING,
  // boxShadow: BETSLIP_ITEM_BOX_SHADOW,
  backgroundColor: 'white',
})

const MultiItemListStyled = styled.ul({
  padding: 0,
  marginBottom: '0.5rem',
})

const MultiSpendStyled = styled(BetInvestmentGroupStyled)({
  width: '100%',
})

const MultiTotalContainerStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  lineHeight: 1,
  padding: '1rem 0',
  fontSize: '1.2rem',
  color: 'black',
  height: '2rem',
  borderTop: '0.1rem solid #ccc',
})

const MultiTotalAmountStyled = styled.span({
  fontSize: '1.6rem',
  fontWeight: 'bold',
  color: 'black',
})
