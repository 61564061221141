import React from 'react'
import styled from '@emotion/styled'

export const EmptyState = () => {
  return <EmptyBetslipMessageStyled>Your Betslip is Empty</EmptyBetslipMessageStyled>
}

const EmptyBetslipMessageStyled = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: '1.6rem',
  textAlign: 'center',
  color: '#aaa',
})
