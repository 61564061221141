import React from 'react'
import { toMoney } from '@mobi/utils/money'

export const Money: React.FC<{ amount: number; prefix?: string } & ToMoneyOptions> = ({
  amount,
  prefix,
  ...options
}) => {
  return <span>{`${prefix ? prefix : ''}${toMoney(amount, options)}`}</span>
}

// =====
// Types
// =====

type ToMoneyOptions = NonNullable<Parameters<typeof toMoney>[1]>
