import { v1 } from 'uuid'
import { Signal } from 'rwwa-rx-state-machine'
import type {
  Selection,
  ToteSelection,
  FobPropositionSelection,
  MysteryDetails,
  EventDetails,
  RaceDetails,
} from '@mobi/betslip/types'
import {
  ProposeAllBetsSuccessful,
  ConfirmAllBetsSuccessful,
  ProposeAllBetsFailed,
  ConfirmAllBetsFailed,
} from './signals'
import { state$ as betslipState$, BetslipState, BetslipItem } from './driver'
import {
  AnalyticsBetData,
  keys as analyticsKeys,
  defaultData as analyticsDefaultData,
  BetConstruction,
} from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import * as Analytics from '@classic/Foundation/Analytics/Analytics'
import {
  BetslipFobBetCommitResponse,
  BetslipResponse,
  BetslipToteBetCommitResponse,
  FobBetCommitResponse,
} from '@core/Data/betslip'
import {
  state$ as launchDarklyState$,
  FeatureFlagState,
  FeatureFlagDriverRecord,
} from '@core/State/LaunchDarklyFeatures/driver'
import { trackBetConfirmation } from '@classic/Foundation/Analytics/Analytics.Bet'
import { BetErrorType, BetErrorTypeLiteral, ensureBetErrorTypeLiteral } from '@core/Data/betting'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import { trackBetSlipAdd } from '@classic/Foundation/Analytics/GoogleTagManagerService'

const defaultBetData = analyticsDefaultData.bet
let betBatchId: string | null

export function registerAnalyticsSubscriptions(): { dispose(): void } {
  betBatchId = v1()

  // -------------------- Bet Step 1 --------------------

  const proposeAllBetsSuccessfulSubscription = ProposeAllBetsSuccessful.signal$.subscribe(
    (x: { data: BetslipResponse[] }) => {
      const trackData: AnalyticsBetData = { ...defaultBetData, construction: 'betslip', betBatchId }

      x.data.forEach(bet => {
        const betErrorType = bet.error && bet.error.type
        const betLegErrorType = bet.legs && bet.legs[0].error && bet.legs[0].error.type
        const errorType = betErrorType ? betErrorType : betLegErrorType

        Analytics.track(analyticsKeys.betslipBetaSelected, {
          bet: {
            ...trackData,
            productType: bet.legs && bet.legs.length > 1 ? 'multi' : 'single',
            errorMessage: mapBetErrorTypeIfNonNullable(errorType),
          },
        })
      })
    }
  )

  const proposeAllBetsFailedSubscription = ProposeAllBetsFailed.signal$.subscribe(x => {
    const step1BetData: AnalyticsBetData = {
      ...defaultBetData,
      construction: 'betslip',
      productType: 'N/A',
      betBatchId: betBatchId,
    }
    const errorMessage = mapBetErrorTypeIfNonNullable(x.data.code || 'Network Error')

    Analytics.track(analyticsKeys.betslipBetaSelected, { bet: { ...step1BetData, errorMessage } })
  })

  // ----------------------------- Bet Step 2 -----------------------------

  const confirmAllBetsFailedSubscription = ConfirmAllBetsFailed.signal$.subscribe(x => {
    const errorType = x.data.code || 'Network Error'
    const step2BetData: AnalyticsBetData = {
      ...defaultBetData,
      construction: 'betslip',
      productType: 'N/A',
      betBatchId,
      errorMessage: mapBetErrorTypeIfNonNullable(errorType),
    }

    Analytics.track(analyticsKeys.betslipBetaReview, { bet: step2BetData })
  })

  const confirmAllBetsSuccessfulSubscription = ConfirmAllBetsSuccessful.signal$
    .withLatestFrom(
      betslipState$,
      launchDarklyState$,
      (confirmAllBetsSuccessful, betslipState, launchDarklyState) => {
        const betslipStateData = betslipState.toJS() as BetslipState
        const getSelection: GetSelection = (id: string) => {
          const item = betslipStateData.items.find(item => item.id === id)
          return !item || !item.selection || !item.selectionDetails
            ? null
            : [item.selection, item.selectionDetails]
        }
        return {
          confirmAllBetsSuccessful,
          launchDarklyState,
          getSelection,
          construction: 'betslip',
        } as BetslipAnalyticsSubscriberParams
      }
    )
    .subscribe(betslipAnalyticsSubscriber)

  return {
    dispose: () => {
      betBatchId = null
      proposeAllBetsSuccessfulSubscription.dispose()
      proposeAllBetsFailedSubscription.dispose()
      confirmAllBetsFailedSubscription.dispose()
      confirmAllBetsSuccessfulSubscription.dispose()
    },
  }
}

type BetslipAnalyticsSubscriberParams = {
  confirmAllBetsSuccessful: Signal
  launchDarklyState: FeatureFlagDriverRecord
  getSelection: GetSelection
  construction: BetConstruction
}

export function betslipAnalyticsSubscriber(props: BetslipAnalyticsSubscriberParams): void {
  const confirmSignalData: BetslipResponse[] = props.confirmAllBetsSuccessful.data

  confirmSignalData.forEach(bet => {
    const isMultiBet = bet.legs && bet.legs.length > 1

    const step2BetData: AnalyticsBetData = {
      ...defaultBetData,
      construction: 'betslip',
      productType: isMultiBet ? 'multi' : 'single',
      betBatchId,
      errorMessage: !bet.success && bet.error ? mapBetErrorTypeIfNonNullable(bet.error.type) : null,
    }

    Analytics.track(analyticsKeys.betslipBetaReview, { bet: step2BetData })

    // ----------------------------- Bet Step 3 -----------------------------
    if (bet.success) {
      if (isMultiBet) {
        const { ticketNumber, winInvestment, bonusBet } = bet.receipt as FobBetCommitResponse

        const step3MultiBetData: AnalyticsBetData = {
          ...defaultBetData,
          specialOffers: null,
          id: ticketNumber,
          construction: 'betslip',
          productType: 'multi',
          placementMethod: 'account',
          mixed: {
            fixed: {
              cost: winInvestment,
              numberOfTickets: 1,
              bonusCashUsed: bonusBet > 0,
              bonusCashAmount: bonusBet,
            },
          },
          betBatchId,
        }

        Analytics.track(analyticsKeys.betAccountProcessed, { bet: step3MultiBetData })
      } else {
        const betItem = props.getSelection(bet.id)
        if (!betItem) return

        const [selection, selectionDetails] = betItem
        const {
          ticketNumber,
          winInvestment = 0,
          placeInvestment = 0,
          specialOffers = [],
          betCost = 0,
          tags,
          bonusBet,
        } = extractReceipt(bet.receipt)
        const { features } = props.launchDarklyState.toJS() as FeatureFlagState

        trackBetConfirmation(
          ticketNumber,
          selection,
          selectionDetails,
          specialOffers || [],
          props.construction,
          betCost,
          winInvestment,
          placeInvestment,
          features,
          betBatchId,
          tags || null,
          null,
          bonusBet
        )
      }
    }
  })
}

function mapBetErrorTypeIfNonNullable(
  betErrorType: Maybe<string | number | BetErrorType>
): Nullable<BetErrorTypeLiteral> {
  if (betErrorType === null || betErrorType === undefined || betErrorType === '') {
    return null
  }
  return ensureBetErrorTypeLiteral(betErrorType)
}

function extractReceipt(
  receipt: BetslipToteBetCommitResponse | BetslipFobBetCommitResponse | undefined
): {
  ticketNumber: number
  winInvestment: number
  placeInvestment: number
  specialOffers: BetSpecialOffer[]
  betCost: number
  tags: string[] | null
  bonusBet?: number
} {
  if (!receipt) {
    return {
      ticketNumber: -1,
      winInvestment: 0,
      placeInvestment: 0,
      specialOffers: [],
      betCost: 0,
      tags: null,
    }
  }

  if ('betCost' in receipt) {
    return {
      ticketNumber: receipt.ticketNumber,
      winInvestment: 0,
      placeInvestment: 0,
      specialOffers: [],
      betCost: receipt.betCost,
      tags: null,
      bonusBet: receipt.bonusBet,
    }
  }

  return {
    ticketNumber: receipt.ticketNumber,
    winInvestment: receipt.winInvestment,
    placeInvestment: receipt.placeInvestment,
    specialOffers: receipt.specialOffers ?? [],
    betCost: 0,
    tags: null,
    bonusBet: receipt.bonusBet,
  }
}

export const trackAddToBetslip = (addedItem: BetslipItem) => {
  const { selection, selectionDetails, betSource } = addedItem

  if (betSource && selection && selectionDetails) {
    const betType =
      (selection as ToteSelection).betType || (selection as FobPropositionSelection).type

    const race =
      (selectionDetails as unknown as RaceDetails).races[0] ||
      (selectionDetails as MysteryDetails).race

    const { meetingCode: raceCode, meetingName } = race || {
      meetingName: null,
      meetingCode: null,
    }

    trackBetSlipAdd({ construction: 'quickbet', source: betSource, raceCode, meetingName, betType })
  }
}

// =====
// Types
// =====

type GetSelection = (id: string) => [Selection, EventDetails] | null
