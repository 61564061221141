import type { AllUpSelection } from '@mobi/betslip/types'
import { BetResponseCode, BetError, BetPrices } from './betting'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'

interface BetslipBet {
  position: number
  tags?: { [tag: string]: unknown }
}

export interface BetslipBetRequest {
  fixedOddsBets: FixedOddsBet[]
  toteBets: ToteBet[]
  allUpBets: AllUpBet[]
  favouriteNumbersBets: FavouriteNumbersBet[]
  toteSportsBets: ToteSportsBet[]
}

export function isBetslipBetRequest(value: unknown): value is BetslipBetRequest {
  if ((value as BetslipBetRequest).toteBets !== undefined) return true
  if ((value as BetslipBetRequest).toteSportsBets !== undefined) return true
  return false
}

export function isBetslipResponses(value: unknown): value is BetslipResponse[] {
  return Array.isArray(value) && (value[0] as BetslipResponse).id !== undefined
}

export interface FixedOddsBet extends BetslipBet {
  id: string
  investmentWin?: number
  investmentPlace?: number
  legs: FixedOddsBetLeg[]
  specials?: FixedOddsBetSpecial[]
  formulaInvestment?: FormulaInvestment
  selectedCampaign?: { id: number; rewardType: string }
}

export interface FormulaInvestment {
  singles?: number
  doubles?: number
  trebles?: number
  pick4?: number
  pick5?: number
}

interface FixedOddsBetSpecial {
  specialSeq: number
  boostPrice?: number
}

export interface ToteBet extends BetslipBet {
  productPlanSeq: number
  fixtureId: string
  fixtureDate: string
  raceNumber: number
  selections: string
  investmentWin?: number
  investmentPlace?: number
  betTransactionId: string
}

export interface AllUpBet extends BetslipBet {
  productPlanSeq: number
  fixtureId: string
  fixtureDate: string
  raceDetails: AllUpSelection['details']
  formulas: AllUpSelection['formulas']
  investment: number
  betTransactionId: string
}

interface FavouriteNumbersBet extends BetslipBet {
  fixtureDate: string
  selectionsGameOne: string
  selectionsGameTwo?: string | undefined
  betTransactionId: string
}

interface ToteSportsBet extends BetslipBet {
  poolSeq: number
  selections: string
  investment: number
  betTransactionId: string
}

export interface FixedOddsBetLeg {
  id: string
  legType: string
  priceWin?: number | null
  pricePlace?: number | null
  handicap?: number
  sportDetails?: SportsDetails
  raceDetails?: RaceDetails
}

export interface SportsDetails {
  propositionSeq?: string
  variantSeq?: string
}

export interface RaceDetails {
  fixtureDate?: string
  fixtureId: string
  raceNumber: number
  starterNumber?: number
  propositionCode?: string
}

interface CommitResponse {
  ticketNumber: number
  amountPaid: number
  betPlacedTime: Date
  accountBalance: number
  campaignActivatedInd: boolean
  bonusBet: number
  numberOfCombinations: number
}

interface ToteBetCommitResponse extends CommitResponse {
  betCost: number
  divXAnnotations: string
  selectionAnnotations: string
}

export interface FobBetCommitResponse extends CommitResponse {
  winInvestment: number
  placeInvestment: number
  projectedWinPayout: number
  projectedPlacePayout: number
  hasSpecialOffer: boolean
  specialOffers: BetSpecialOffer[] | null
  formulaResponse: FormulaResponse | null
}

export interface FormulaResponse {
  multi: FormulaReceipt | null
  singles: FormulaReceipt | null
  doubles: FormulaReceipt | null
  trebles: FormulaReceipt | null
  pick4: FormulaReceipt | null
  pick5: FormulaReceipt | null
}

interface FormulaReceipt {
  formulaNumber: number
  stake: number
  numberOfCombinations: number
  combinedStake: number
  combinedReturn: number
}

export type BetslipToteBetCommitResponse = ToteBetCommitResponse & {
  betPlacedTime: Date
}

export type BetslipFobBetCommitResponse = FobBetCommitResponse & {
  betPlacedTime: Date
}

export interface BetslipResponse {
  id: string
  error: BetError | null
  success: boolean
  legs?: FobLegResponse[] // why not in FobBetCommitResponse?
  specialOffers?: BetSpecialOffer[]
  receipt?: BetslipToteBetCommitResponse | BetslipFobBetCommitResponse
  accountBalance?: number
}

export interface FobLegResponse {
  id: string
  error: BetError | null
  prices?: BetPrices
}

export interface BetslipErrorResponse {
  code: BetResponseCode
  response: {
    message: string
    accountBalance?: number
  }
}
