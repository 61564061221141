import React from 'react'
import { Observable } from 'rx'
import type { FobSelection } from '@mobi/betslip/types'
import { state$ as betSlipState$, BetslipState, BetslipItem } from '../../driver'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { ErrorMessage } from '../Messages/ErrorMessage'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { EstimatedReturn } from '../EstimatedReturn/EstimatedReturn'
import { ConfirmMessage } from '../Messages/ConfirmMessage'
import { InvalidBetsMessage } from '../Messages/InvalidBetsMessage'
import { ReviewChangesMessage } from '../Messages/ReviewChangesMessage'
import { observeImmutable } from '@core/Components/HOCs'
import {
  SummaryStyled,
  FooterStyled,
  MessagesContainerStyled,
  FooterSummaryHeadingStyled,
  FooterNoticeBoxWrapper,
} from './FooterSummary.styles'
import { PriceChange, getPriceChange } from '@core/Components/Text/utils'
import { BetsPlacedMessage } from '../Messages/BetsPlacedMessage'
import {
  getBetsToPlace,
  isFatalErrorType,
  getBetsInMulti,
  isValidMulti,
  hasBetPlacementFault,
  isDuplicateBonusBetErrorType,
  isUnspecifiedErrorType,
} from '../../helpers/state'
import {
  PlaceBetsButton,
  ConfirmBetsButton,
  PendingBetsButton,
  EditBetsButton,
  CloseButton,
  LoginButton,
  DepositButton,
  KeepBetsButton,
} from './FooterSummaryButtons'
import { BetErrorType } from '@core/Data/betting'

type FooterSummaryComponentProps = Pick<
  BetslipState,
  | 'items'
  | 'hasProposed'
  | 'isBusy'
  | 'apiErrorMessage'
  | 'multiInvestment'
  | 'multiReceipt'
  | 'multiBetError'
> & { isLoggedIn: boolean }

const isPriceChangeDown = ({ selection }: BetslipItem): boolean => {
  if (!selection || !isFobSelection(selection)) {
    return false
  }
  const winPriceChange = getPriceChange(selection.winPriceLastSeen, selection.winPrice)
  const placePriceChange = selection.placePrice
    ? getPriceChange(selection.placePriceLastSeen, selection.placePrice)
    : PriceChange.None
  return winPriceChange === PriceChange.Decrease || placePriceChange === PriceChange.Decrease
}

export class FooterSummaryComponent extends React.Component<FooterSummaryComponentProps> {
  public render() {
    const {
      items,
      multiInvestment,
      multiReceipt,
      multiBetError,
      hasProposed,
      isBusy,
      isLoggedIn,
      apiErrorMessage,
    } = this.props

    const isValidMultiPresent =
      !multiReceipt && isValidMulti(multiInvestment, multiBetError, getBetsInMulti(items))

    const singlesLeftToPlace = getBetsToPlace(items).count()
    const numberOfBets = singlesLeftToPlace + (isValidMultiPresent ? 1 : 0)

    const hasInsufficientFundsError =
      (hasProposed &&
        !!multiBetError &&
        multiBetError.betErrorType === BetErrorType.InsufficientFunds) ||
      items.some(x => x.betErrorType === BetErrorType.InsufficientFunds)
    const hasErrors =
      !!multiBetError ||
      items.some(x => x.betErrorType !== undefined || !!x.multiBetLegError || isPriceChangeDown(x))
    const hasPlacedBets = items.some(x => !!x.receipt) || !!multiReceipt

    const hasSeriousMultiError =
      !!multiBetError &&
      (isFatalErrorType(multiBetError.betErrorType) ||
        hasBetPlacementFault(multiBetError.betErrorType) ||
        isDuplicateBonusBetErrorType(multiBetError.betErrorType) ||
        isUnspecifiedErrorType(multiBetError.betErrorType))

    const hasMultiLeftToPlace = !multiReceipt && !hasSeriousMultiError && isValidMultiPresent
    const hasBetsLeftToPlace = singlesLeftToPlace > 0 || hasMultiLeftToPlace

    const isReadyToConfirm = hasBetsLeftToPlace || !hasPlacedBets
    const isBettingComplete = !hasBetsLeftToPlace && hasPlacedBets

    return (
      <FooterStyled>
        {(hasProposed || !!apiErrorMessage) && (
          <MessagesContainerStyled>
            <FooterNoticeBoxWrapper>
              {!!apiErrorMessage && <ErrorMessage errorMessage={apiErrorMessage} />}

              {!hasBetsLeftToPlace && !hasPlacedBets && <InvalidBetsMessage />}

              {hasProposed && hasBetsLeftToPlace && !hasErrors && <ConfirmMessage />}

              {hasBetsLeftToPlace && hasErrors && (
                <ReviewChangesMessage includeCancellationWarning={hasProposed} />
              )}

              {hasPlacedBets && <BetsPlacedMessage items={items} multiReceipt={multiReceipt} />}
            </FooterNoticeBoxWrapper>
          </MessagesContainerStyled>
        )}

        <SummaryStyled>
          {(!hasProposed || hasBetsLeftToPlace) && (
            <Grid margin='0 0 0.5rem 0'>
              <GridCell align='left'>
                <FooterSummaryHeadingStyled data-testid='FooterSummary.TotalBets'>
                  Total Bets <span>{numberOfBets}</span>
                </FooterSummaryHeadingStyled>
              </GridCell>
              <GridCell align='right'>
                <EstimatedReturn />
              </GridCell>
            </Grid>
          )}

          <Grid padding='0.5rem'>
            {(() => {
              if (!isLoggedIn) {
                return (
                  <GridRow>
                    {hasProposed && (
                      <GridCell valign='middle'>
                        <EditBetsButton disabled={isBusy} />
                      </GridCell>
                    )}
                    <GridCell valign='middle'>
                      <LoginButton isBusy={isBusy} />
                    </GridCell>
                  </GridRow>
                )
              }

              if (!hasProposed) {
                return (
                  <GridCell valign='middle'>
                    <PlaceBetsButton disabled={!(numberOfBets > 0)} isBusy={isBusy} />
                  </GridCell>
                )
              }

              if (isReadyToConfirm) {
                return (
                  <GridRow>
                    <GridCell valign='middle'>
                      <EditBetsButton disabled={isBusy} />
                    </GridCell>
                    <GridCell valign='middle'>
                      {hasInsufficientFundsError ? (
                        <DepositButton />
                      ) : (
                        <ConfirmBetsButton
                          disabled={!hasBetsLeftToPlace && !hasPlacedBets}
                          isBusy={isBusy}
                        />
                      )}
                    </GridCell>
                  </GridRow>
                )
              }

              if (isBettingComplete) {
                return (
                  <GridRow>
                    <GridCell valign='middle'>
                      <PendingBetsButton />
                    </GridCell>
                    <GridCell valign='middle'>
                      <KeepBetsButton />
                    </GridCell>
                    <GridCell valign='middle'>
                      <CloseButton />
                    </GridCell>
                  </GridRow>
                )
              }

              return undefined
            })()}
          </Grid>
        </SummaryStyled>
      </FooterStyled>
    )
  }
}

const footerSummaryState$ = Observable.combineLatest(
  betSlipState$,
  userAccountState$,
  (betslipRecord, userAccountRecord): FooterSummaryComponentProps => {
    const betslipState: BetslipState = betslipRecord.toJS()
    return {
      items: betslipRecord.items,
      multiInvestment: betslipState.multiInvestment,
      multiReceipt: betslipState.multiReceipt,
      multiBetError: betslipState.multiBetError,
      hasProposed: betslipState.hasProposed,
      isBusy: betslipState.isBusy,
      isLoggedIn: userAccountRecord.isLoggedIn ?? false,
      apiErrorMessage: betslipState.apiErrorMessage,
    }
  }
)

export const FooterSummary = observeImmutable(
  footerSummaryState$,
  ({
    record: {
      items,
      multiBetError,
      multiInvestment,
      multiReceipt,
      hasProposed,
      isBusy,
      isLoggedIn,
      apiErrorMessage,
    },
  }) => (
    <FooterSummaryComponent
      items={items}
      multiBetError={multiBetError}
      multiInvestment={multiInvestment}
      multiReceipt={multiReceipt}
      isBusy={isBusy}
      hasProposed={hasProposed}
      isLoggedIn={isLoggedIn}
      apiErrorMessage={apiErrorMessage}
    />
  )
)

// Local Helpers

function isFobSelection(selection: BetslipItem['selection']): selection is FobSelection {
  return !!(selection && 'winPrice' in selection)
}
