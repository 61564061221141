import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { BetSlipItem, BetSlipBetsState } from '@mobi/betslip/types'

// TODO: Focus slice on item store and manipulation

const initialState: BetSlipBetsState = {
  items: [],

  apiErrorMessage: null,
  isSingleExpanded: true,
  isMultiExpanded: true,
  hasProposed: false,
  isBusy: false,
  multiBetError: null,
  multiInvestment: {
    value: 0,
    f1: 0,
    f2: 0,
    f3: 0,
    f4: 0,
    f5: 0,
    bonusBetId: null,
    isBonusBet: false,
  },
  multiReceipt: null,
}

const betsSlice = createSlice({
  name: 'betslip/bets',
  initialState,
  reducers: {
    addItemsToBetSlip(state, { payload: items }: PayloadAction<BetSlipItem[]>) {
      state.items.push(...items)
    },

    clearAllBetSlipItems(state) {
      state.items = []
    },

    removeItemFromBetSlipById(state, { payload: id }: PayloadAction<BetSlipItem['id']>) {
      state.items = state.items.filter(item => item.id !== id)
    },
  },
})

export const { addItemsToBetSlip, clearAllBetSlipItems, removeItemFromBetSlipById } =
  betsSlice.actions

const allowPersistForKeys: (keyof BetSlipBetsState)[] = ['items']

export default persistReducer(
  {
    key: 'betslip/bets',
    storage,
    debug: !PRODUCTION,
    whitelist: allowPersistForKeys,
  },
  betsSlice.reducer
)
