import React from 'react'
import styled from '@emotion/styled'
import { colors, radius, spacing } from '@mobi/component-library/Theme/Common'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import type { BetSlipItem, FobSelection, BetSlipBetsState } from '@mobi/betslip/types'
import { getPriceChange, PriceChange } from '@mobi/betslip/helpers/getPriceChange'
import { ErrorMessage } from './Components/Messages/ErrorMessage'
import { EstimatedReturn } from './Components/EstimatedReturn/EstimatedReturn'
import { ConfirmMessage } from './Components/Messages/ConfirmMessage'
import { InvalidBetsMessage } from './Components/Messages/InvalidBetsMessage'
import { ReviewChangesMessage } from './Components/Messages/ReviewChangesMessage'
import { BetsPlacedMessage } from './Components/Messages/BetsPlacedMessage'
import {
  getBetsToPlace,
  isFatalErrorType,
  getBetsInMulti,
  isValidMulti,
} from '@mobi/betslip/helpers/state'
import {
  PlaceBetsButton,
  ConfirmBetsButton,
  PendingBetsButton,
  EditBetsButton,
  CloseButton,
  LoginButton,
  DepositButton,
  KeepBetsButton,
  ClearBetSlipButton,
} from './Components/Buttons'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { selectBetSlipItems } from '@mobi/betslip/Store/Bets/selectors'

export const Footer: React.FC = () => {
  const items = useAppSelector(selectBetSlipItems)

  // TODO: Use redux state
  const {
    multiBetError,
    multiInvestment,
    multiReceipt,
    isBusy,
    hasProposed,
    isLoggedIn,
    apiErrorMessage,
  } = {
    multiBetError: null,
    multiInvestment: {} as BetSlipBetsState['multiInvestment'],
    multiReceipt: {} as BetSlipBetsState['multiReceipt'],
    isBusy: false,
    hasProposed: false,
    isLoggedIn: true,
    apiErrorMessage: null,
  } as Pick<
    BetSlipBetsState,
    | 'hasProposed'
    | 'isBusy'
    | 'apiErrorMessage'
    | 'multiInvestment'
    | 'multiReceipt'
    | 'multiBetError'
  > & { isLoggedIn: boolean }

  const isValidMultiPresent =
    !multiReceipt && isValidMulti(multiInvestment, multiBetError, getBetsInMulti(items))

  const singlesLeftToPlace = getBetsToPlace(items).length
  const numberOfBets = singlesLeftToPlace + (isValidMultiPresent ? 1 : 0)

  const hasInsufficientFundsError =
    (hasProposed && !!multiBetError && multiBetError.betErrorType === 'InsufficientFunds') ||
    items.some(x => x.betErrorType === 'InsufficientFunds')

  const hasErrors =
    !!multiBetError ||
    items.some(
      x =>
        x.betErrorType !== undefined ||
        !!x.multiBetLegError ||
        isPriceChangeDown(x as unknown as BetSlipItem)
    )
  const hasPlacedBets = items.some(x => !!x.receipt) || !!multiReceipt

  const hasSeriousMultiError =
    !!multiBetError &&
    (isFatalErrorType(multiBetError.betErrorType) ||
      multiBetError.betErrorType === 'BetPlacementFault' ||
      multiBetError.betErrorType === 'DuplicateBonusBet' ||
      multiBetError.betErrorType === 'Unspecified')

  const hasMultiLeftToPlace = !multiReceipt && !hasSeriousMultiError && isValidMultiPresent
  const hasBetsLeftToPlace = singlesLeftToPlace > 0 || hasMultiLeftToPlace

  const isReadyToConfirm = hasBetsLeftToPlace || !hasPlacedBets
  const isBettingComplete = !hasBetsLeftToPlace && hasPlacedBets

  const shouldShowClear = items.length > 0 && !hasProposed && !isBusy

  return (
    <WrapperStyled>
      {(hasProposed || !!apiErrorMessage) && (
        <MessagesContainerStyled>
          <FooterNoticeBoxWrapper>
            {!!apiErrorMessage && <ErrorMessage errorMessage={apiErrorMessage} />}

            {!hasBetsLeftToPlace && !hasPlacedBets && <InvalidBetsMessage />}

            {hasProposed && hasBetsLeftToPlace && !hasErrors && <ConfirmMessage />}

            {hasBetsLeftToPlace && hasErrors && (
              <ReviewChangesMessage includeCancellationWarning={hasProposed} />
            )}

            {hasPlacedBets && <BetsPlacedMessage items={items} multiReceipt={multiReceipt} />}
          </FooterNoticeBoxWrapper>
        </MessagesContainerStyled>
      )}

      <SummaryStyled>
        {(!hasProposed || hasBetsLeftToPlace) && (
          <Grid margin='0 0 0.5rem 0'>
            <GridCell align='left'>
              <FooterSummaryHeadingStyled>
                Total Bets <span>{numberOfBets}</span>
              </FooterSummaryHeadingStyled>
            </GridCell>
            <GridCell align='right'>
              <EstimatedReturn />
            </GridCell>
          </Grid>
        )}

        <Grid padding='0.5rem'>
          {(() => {
            if (!isLoggedIn) {
              return (
                <GridRow>
                  {hasProposed && (
                    <GridCell valign='middle'>
                      <EditBetsButton disabled={isBusy} />
                    </GridCell>
                  )}
                  <GridCell valign='middle'>
                    <LoginButton isBusy={isBusy} />
                  </GridCell>
                </GridRow>
              )
            }

            if (!hasProposed) {
              return (
                <GridRow>
                  <GridCell valign='middle'>{shouldShowClear && <ClearBetSlipButton />}</GridCell>
                  <GridCell valign='middle'>
                    <PlaceBetsButton disabled={!(numberOfBets > 0)} isBusy={isBusy} />
                  </GridCell>
                </GridRow>
              )
            }

            if (isReadyToConfirm) {
              return (
                <GridRow>
                  <GridCell valign='middle'>
                    <EditBetsButton disabled={isBusy} />
                  </GridCell>
                  <GridCell valign='middle'>
                    {hasInsufficientFundsError ? (
                      <DepositButton />
                    ) : (
                      <ConfirmBetsButton
                        disabled={!hasBetsLeftToPlace && !hasPlacedBets}
                        isBusy={isBusy}
                      />
                    )}
                  </GridCell>
                </GridRow>
              )
            }

            if (isBettingComplete) {
              return (
                <GridRow>
                  <GridCell valign='middle'>
                    <PendingBetsButton />
                  </GridCell>
                  <GridCell valign='middle'>
                    <KeepBetsButton />
                  </GridCell>
                  <GridCell valign='middle'>
                    <CloseButton />
                  </GridCell>
                </GridRow>
              )
            }

            return undefined
          })()}
        </Grid>
      </SummaryStyled>
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

function isFobSelection(selection: BetSlipItem['selection']): selection is FobSelection {
  return !!(selection && 'winPrice' in selection)
}

const isPriceChangeDown = ({ selection }: BetSlipItem): boolean => {
  if (!selection || !isFobSelection(selection)) {
    return false
  }
  const winPriceChange = getPriceChange(selection.winPriceLastSeen, selection.winPrice)
  const placePriceChange: PriceChange = selection.placePrice
    ? getPriceChange(selection.placePriceLastSeen, selection.placePrice)
    : 'NoChange'
  return winPriceChange === 'Down' || placePriceChange === 'Down'
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  padding: spacing.md,
  paddingBottom: spacing.lg,
  borderTopLeftRadius: radius.lgx4,
  borderTopRightRadius: radius.lgx4,
  backgroundColor: colors.lavender[900],
})

const SummaryStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: 'white',
  width: '100%',
  height: '8rem',
  boxSizing: 'border-box',
})

const FooterSummaryHeadingStyled = styled.span({
  fontSize: '1.2rem',
  color: 'white',

  '> span': {
    fontWeight: 'bold',
    color: '#eee',
    margin: 0,
  },
})

const MessagesContainerStyled = styled.div({
  backgroundColor: 'white',
  padding: '1rem',
  overflow: 'auto',
})

const FooterNoticeBoxWrapper = styled.div({
  backgroundColor: 'white',
  color: '#222',

  '& > div': {
    marginBottom: 0,
  },

  '& > div:last-child': {
    borderBottom: 'none',
  },
})
