import React from 'react'
import styled from '@emotion/styled'
import type { BetSlipBetsState } from '@mobi/betslip/types'
import { Money } from '@mobi/betslip/Components/Common/Money/Money'
import { calculateTotalStake } from '@mobi/betslip/helpers/calculator'

// TODO: Use redux state
export const TotalStake = () => (
  <TotalStakeComponent
    totalStake={calculateTotalStake(
      [], // items
      {} as BetSlipBetsState['multiInvestment'], // multiInvestment
      null, //m ultiBetError
      false // hasMultiBeenPlaced: boolean
    )}
  />
)

const TotalStakeComponent: React.FC<{ totalStake: number }> = ({ totalStake }) => {
  return (
    <TotalStakeValueStyled>
      <Money amount={totalStake} />
    </TotalStakeValueStyled>
  )
}

const TotalStakeValueStyled = styled.span({
  fontWeight: 'bold',
  fontSize: '1.4rem',
})
