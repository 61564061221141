import React, { useCallback, useEffect, useRef, useState } from 'react'
import type { GooglePayDepositRequest } from '@mobi/api-types'
import { SpinnerStandalone } from '@mobi/component-library/Common/Spinner'
import type { PanelProps } from '../../types'
import { useBraintreeClient } from '../../../../../Hooks/useBraintreeClient'
import { useGooglePayAvailability, useGooglePayDeposit } from '../../../../../Hooks/GooglePay'
import { isGooglePayError } from '../../../../../Hooks/GooglePay/initGooglePayWeb'
import { getGooglePaymentsClient } from '../../../../../Utils/googlePay'
import { LoadingContainerStyled } from '../../DepositBody.styles'
import { PaymentSDKButtonContainerStyled } from '../Payment.styles'
import type { DepositRequestWithoutDeviceData } from '../../../../../Hooks/useDeposit'
import { InfoBox } from '@mobi/component-library/Common/V2'
import { DepositError } from '../../DepositError'

export const GooglePayButtonWeb = ({
  initialData: { clientTokenThreeDSecure, transactionId },
  accountNumber,
  depositAmount,
  isDepositAllowed,
  onStart,
  onCancel,
  onDepositing,
  onSuccess,
  onFailure,
}: PanelProps) => {
  // Due to Google Pay's button rendering through an SDK and requiring a onClick
  // function with signature `() => void`, we need to keep the `pay` function
  // in a ref to prevent rerenders and allow us to change the dependencies
  const payFunctionRef = useRef<VoidFunction>()
  const buttonContainerRef = useRef<HTMLDivElement>(null)
  const isGooglePayAvailable = useGooglePayAvailability(clientTokenThreeDSecure)
  const { isReady, client } = useBraintreeClient(clientTokenThreeDSecure)
  const { verify, deposit } = useGooglePayDeposit({
    braintreeClient: client,
    accountNumber,
    transactionId,
  })
  const [isButtonReady, setButtonReady] = useState(false)

  const onGooglePayConfirmed = useCallback(
    async (data: DepositRequestWithoutDeviceData<GooglePayDepositRequest>) => {
      onDepositing()

      try {
        const { isSuccess, ...errorDetails } = await deposit(data)

        if (isSuccess) {
          onSuccess(depositAmount)
        } else {
          onFailure(DepositError.fromErrorDetails(errorDetails))
        }
      } catch (error) {
        if (isGooglePayError(error)) {
          // TODO: Figure out what the appropriate reason is based on statusCode
          return onFailure(
            new DepositError(
              'generic_failure',
              transactionId,
              error.statusCode,
              error.statusMessage
            )
          )
        }

        onFailure(DepositError.coerce(error, transactionId))
      }
    },
    [deposit, onDepositing, onFailure, onSuccess, transactionId]
  )

  const pay = useCallback(async () => {
    onStart()

    const canDeposit = await isReady()
    const braintreeClient = client.current

    if (!canDeposit || !braintreeClient) {
      onCancel?.()
      return
    }

    try {
      await verify({
        depositAmount,
        onSuccess: onGooglePayConfirmed,
        onFailure,
        onCancel,
      })
    } catch (unknownError) {
      const error = unknownError as Error | string | google.payments.api.PaymentsError

      if (isGooglePayError(error)) {
        if (error.statusCode === 'CANCELED') {
          return onCancel?.()
        }

        // TODO: Figure out what the appropriate reason is based on statusCode
        return onFailure(
          new DepositError('generic_failure', transactionId, error.statusCode, error.statusMessage)
        )
      }

      onFailure(DepositError.coerce(error, transactionId))
    }
  }, [
    onStart,
    isReady,
    client,
    onCancel,
    verify,
    depositAmount,
    onGooglePayConfirmed,
    onFailure,
    transactionId,
  ])

  useEffect(() => {
    // Don't render the Google Pay button when Google Pay is not available
    // or when the button has already been rendered
    if (isGooglePayAvailable !== 'available' || isButtonReady) {
      return
    }

    // See https://developers.google.com/pay/api/web/guides/resources/customize for button customization options
    getGooglePaymentsClient().then(client => {
      const button = client.createButton({
        onClick: () => payFunctionRef.current?.(),
        buttonType: 'plain', // Only display "Pay" along the Google Pay icon
        buttonColor: 'default',
        buttonLocale: 'en',
        buttonSizeMode: 'fill',
      })

      buttonContainerRef.current?.append(button)
      setButtonReady(true)
    })
  }, [isGooglePayAvailable, isButtonReady])

  // Update the `pay` function ref when it has changed
  useEffect(() => {
    payFunctionRef.current = pay
  }, [pay])

  return (
    <>
      <div style={{ display: isButtonReady ? 'block' : 'none' }}>
        <PaymentSDKButtonContainerStyled
          id='googlepay-container'
          ref={buttonContainerRef}
          aria-disabled={!isDepositAllowed}
          isDisabled={!isDepositAllowed}
        />
      </div>

      {!isButtonReady && isGooglePayAvailable !== 'unavailable' && (
        <LoadingContainerStyled>
          <SpinnerStandalone />
        </LoadingContainerStyled>
      )}

      {!isButtonReady && isGooglePayAvailable === 'unavailable' && (
        <InfoBox size='md' color='red'>
          Google Pay is unavailable on your device.
        </InfoBox>
      )}
    </>
  )
}
