import React from 'react'
import styled from '@emotion/styled'
import { Button, Icon } from '@mobi/component-library/Common/V2'
import { colors, spacing } from '@mobi/component-library/Theme/Common'

export const Footer: React.FC<{
  leftSection?: React.ReactNode
  rightSection?: React.ReactNode
}> = ({ leftSection, rightSection }) => {
  return (
    <WrapperStyled>
      <div>{leftSection}</div>
      <div>{rightSection}</div>
    </WrapperStyled>
  )
}

// Only add buttons here that are used in BOTH BetSlip + Transacted

export const ShareButton: React.FC<ButtonProps> = ({ onClick, disabled }) => (
  <Button size='xs' color='tertiary_grey' isIconOnlyButton onClick={onClick} disabled={disabled}>
    <Icon name='LineShare02' size='1.6rem' color={colors.black} />
  </Button>
)

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: spacing.smx1,
  borderTop: '0.5px solid ' + colors.neutral[200],
})

// =====
// Types
// =====

type ButtonProps = Pick<React.ComponentProps<typeof Button>, 'onClick' | 'disabled'>
