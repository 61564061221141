import React from 'react'
import type { BetSlipItem } from '@mobi/betslip/types'
import { isFatalErrorType } from '@mobi/betslip/helpers/state'
import { isFobSportsSelection, isToteSelection } from '@mobi/betslip/helpers/typeGuards'
import { FobInvestment, ToteInvestment, SportsFixed } from './Components'

export const BetInvestment: React.FC<{
  item: BetSlipItem
  isEditable: boolean
}> = ({ item, isEditable }) => {
  // TODO: Use state for login
  const isLoggedIn = true

  if (!item.selection || isFatalErrorType(item.betErrorType) || item.receipt) return null

  if (isFobSportsSelection(item.selection)) {
    return <SportsFixed />
  }

  if (!isToteSelection(item.selection)) {
    return <FobInvestment item={item} isLoggedIn={isLoggedIn} isEditable={isEditable} />
  }

  if (isToteSelection(item.selection)) {
    return <ToteInvestment item={item} isEditable={isEditable} />
  }

  return null
}
