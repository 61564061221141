import React from 'react'
import type { BetSlipItem } from '@mobi/betslip/types'
import type { ReceiptItem } from '@mobi/betslip/types/todo'
import { toMoney } from '@mobi/utils/money'

export const BetsPlacedMessage: React.FC<{
  items: BetSlipItem[]
  multiReceipt: ReceiptItem | null
}> = ({ items, multiReceipt }) => {
  let placedBetsCount: number = 0
  let placedBetsAmount: number = 0

  items.forEach(item => {
    if (item.receipt) {
      placedBetsCount++
      placedBetsAmount += item.receipt.amountPaid as number
    }
  })

  if (multiReceipt) {
    placedBetsCount++
    placedBetsAmount += multiReceipt.amountPaid as number
  }

  if (placedBetsCount === 0) return null

  return (
    <div>
      <div>
        Success:{' '}
        {`${placedBetsCount} bet${placedBetsCount > 1 ? 's' : ''} placed totalling ${toMoney(
          placedBetsAmount
        )}`}
      </div>
    </div>
  )
}
