import { createSlice } from '@reduxjs/toolkit'

// TODO: Focus slice on workflow

const initialState = {}

const workflowSlice = createSlice({
  name: 'betslip/workflow',
  initialState,
  reducers: {},
})

// export const {} = workflowSlice.actions

export default workflowSlice.reducer
