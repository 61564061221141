export const getPriceChange = (
  oldPrice: number | undefined | null,
  newPrice: number
): PriceChange => {
  if (!oldPrice) return 'NoChange'

  if (oldPrice > newPrice) {
    return 'Down'
  } else if (oldPrice < newPrice) {
    return 'Up'
  }

  return 'NoChange'
}

export type PriceChange = 'Up' | 'Down' | 'NoChange'
