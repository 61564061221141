import { createSelector } from '@reduxjs/toolkit'
import type { AppState } from '..'

export const selectBetSlipItems = (state: AppState) => state.betslip.bets.items

export const selectBetSlipItemCount = createSelector(
  [(state: AppState) => state.betslip.bets.items],
  items => {
    return items.length
  }
)
