import React from 'react'
import styled from '@emotion/styled'
import type { BetSlipItem } from '@mobi/betslip/types'
import { Accordion } from '@mobi/component-library/Common/V2/Accordion'
import { spacing } from '@mobi/component-library/Theme/Common'
import { hasInvestment, hasErrors, hasBeenPlaced } from '../../helpers/state'
import { SingleItem } from './Components/SingleItem'

// TODO: Modify Accordion to be controlled, so state can be persisted
const handleExpandablePanelClick = () => {
  // ToggleBetslipExpandable('Single')
}

export const Singles: React.FC<{ items: BetSlipItem[] }> = ({ items }) => {
  const { hasProposed } = {
    hasProposed: false,
  }

  let filteredItems: BetSlipItem[] = items
  if (hasProposed) {
    filteredItems = items.filter(
      item => hasErrors(item) || hasBeenPlaced(item) || hasInvestment(item)
    )
  }

  if (!filteredItems || filteredItems.length === 0) return null

  return (
    <Accordion
      title={`Single (${filteredItems.length})`}
      onToggle={handleExpandablePanelClick}
      shouldStartExpanded={true}
      shouldUseDefaultTheme={false}
    >
      <ListStyled>
        {filteredItems.map(item => (
          <SingleItem key={item.id} item={item} />
        ))}
      </ListStyled>
    </Accordion>
  )
}

const ListStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx1,
  paddingBottom: spacing.smx1,
})
