import styled from '@emotion/styled'

export const SingleAllUpFormulaContainerStyled = styled.ul({
  listStyle: 'none',
  margin: '0 0 0.5rem',
  padding: '0.5rem 0 0.5rem 0',
  display: 'flex',
  justifyContent: 'flex-end',
  lineHeight: 1.2,
  borderBottom: '0.1rem solid #eee',
})

export const SingleAllUpFormulaItemStyled = styled.li({
  width: `calc(100% / 6)`,
  fontSize: '1.1rem',
  textAlign: 'center',
  color: '#222',
  boxSizing: 'border-box',
  borderRight: '0.1rem solid #eee',

  '&:last-child': {
    borderRight: 'none',
  },
})

export const SingleAllUpFormulaTitleStyled = styled.span({
  display: 'block',
})
