import React from 'react'
import styled from '@emotion/styled'
import type { BetSlipItem, MultiInvestment, BetSlipBetsState } from '@mobi/betslip/types'
import { calculateEstReturn } from '@mobi/betslip/helpers/calculator'
import { getBetsToPlace, getBetsInMulti, isValidMulti } from '@mobi/betslip/helpers/state'

export const EstimatedReturn = () => {
  // TODO: Use state
  const items = [] as BetSlipItem[]

  // TODO: Use state
  const multiReceipt = {} as BetSlipBetsState['multiReceipt']

  // TODO: Use state
  const multiInvestment: MultiInvestment = {} as MultiInvestment

  const multiItems = getBetsInMulti(items)
  const placeableItems = getBetsToPlace(items)
  const placeableMultis =
    !multiReceipt && isValidMulti(multiInvestment, null, multiItems) ? multiItems : []
  const estimatedReturn = calculateEstReturn(placeableItems, placeableMultis, multiInvestment)

  const displayValue = Number.isFinite(estimatedReturn) && estimatedReturn > 0

  return (
    <EstimatedReturnHeadingStyled>
      Est. Return <span>{displayValue ? <span>{estimatedReturn}</span> : <span>N/A</span>}</span>
    </EstimatedReturnHeadingStyled>
  )
}

// ======
// Styles
// ======

const EstimatedReturnHeadingStyled = styled.span({
  fontSize: '1.2rem',
  color: 'white',

  '> span': {
    fontWeight: 'bold',
    color: '#eee',
    margin: 0,
  },
})
