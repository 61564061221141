import dayjs from 'dayjs'
import type { RaceDetails, Acceptor, Race } from '@mobi/betslip/types'
import { PlanSeq } from '@core/Data/betting'
import {
  RacePageDataTransferObject,
  ResultsPageDataTransferObject,
} from '@classic/Betting-v2/DataTransferObjects/DataTransferObjects'
import { get } from '@classic/Foundation/Services/ApiService'
import { LoadBetErrors } from '../../types'

export const getSelectionDetails = async (
  fixtureId: string,
  fixtureDate: Date,
  raceNumbers: number[],
  selections: string[][],
  planSeq: PlanSeq,
  shouldForceWinOnly = false
): Promise<{ selectionDetails: RaceDetails; shouldAllowPlaceInvestment: boolean }> => {
  const isMultiRace = raceNumbers.length > 1
  const isWinPlaceDetails = isWinPlace(planSeq)

  const formattedDate = dayjs(fixtureDate).format('YYYY-MM-DD')
  const raceArray = isWinPlaceDetails ? [raceNumbers[0]] : raceNumbers
  const meeting = await get<RacePageDataTransferObject | ResultsPageDataTransferObject>({
    url: `/api/meetings/${fixtureId}/${formattedDate}/races/${raceArray.join(',')}`,
  })

  let shouldAllowPlaceInvestment = false
  if (isWinPlaceDetails) {
    const { HasPlacePool, FixedOddsInfo } = meeting.MeetingInformation.SelectedRace
    shouldAllowPlaceInvestment =
      !shouldForceWinOnly && !!(HasPlacePool || FixedOddsInfo?.IsPlaceAvailable)
  }

  if (isBettingClosedOnRace(meeting)) throw new Error(LoadBetErrors.BettingClosed)

  const { MeetingInformation: meetingInformation, RaceStarters: raceStarters } = meeting

  const races = meetingInformation.Races.filter(race => raceNumbers.includes(race.RaceNumber)).map(
    (raceDetails, index) => {
      const acceptorKeysList = isMultiRace
        ? selections[index]
        : selections.flatMap(selection => selection)

      const race: Race = {
        key: raceDetails.Key,
        raceNumber: raceDetails.RaceNumber,
        meetingName: meetingInformation.MeetingName,
        meetingCode: meetingInformation.MeetingCode,
        meetingId: meetingInformation.MeetingId,
        meetingDate: meetingInformation.MeetingDate,
        distance: raceDetails.Distance,
        raceTime: raceDetails.RaceTime,
        type: raceDetails.RaceType,
        isFixedOddsRace: raceDetails.IsFixedOddsRace,
        // Either Split the keys across races, or all on single race
        acceptorKeys: acceptorKeysList.map(acceptor =>
          createAcceptorKey(raceDetails.RaceNumber, acceptor)
        ),
      }

      return race
    }
  )

  // Flatten acceptors across all races
  const acceptors = raceStarters
    .filter(race => raceNumbers.includes(race.RaceKey.RaceNumber))
    .flatMap(race => {
      return race.Starters.map(starter => {
        const acceptor: Acceptor = {
          key: createAcceptorKey(race.RaceKey.RaceNumber, starter.Number),
          name: starter.Name,
          number: starter.Number,
          type: starter.Type,
          imageUrl: starter.SilkImages.small.url,
          imageUrlWithSizes: starter.SilkImages,
          meetingName: meetingInformation.MeetingName,
          isScratched: starter.IsScratched,
          scratchType: starter.ScratchType,
          fixedOdds: {
            win: starter.FixedOddsStarterInfo?.DisplayWinDividend,
            place: starter.FixedOddsStarterInfo?.DisplayPlaceDividend,
            isSuspended: starter.FixedOddsStarterInfo?.IsSuspended,
          },
          toteOdds: {
            win: starter.DisplayWinDividend,
            place: starter.DisplayPlaceDividend,
          },
        }

        return acceptor
      })
    })

  return {
    selectionDetails: {
      races,
      acceptors,
    },
    shouldAllowPlaceInvestment,
  }
}

// Local Helpers

function createAcceptorKey(raceNumber: number, selection: string | number): string {
  return `${raceNumber}-${selection}`
}

function isWinPlace(planSeq: number): boolean {
  if ([PlanSeq.Win, PlanSeq.Place, PlanSeq.WinAndPlace, PlanSeq.FOBRacing].includes(planSeq))
    return true
  return false
}

function isBettingClosedOnRace(
  meeting: RacePageDataTransferObject | ResultsPageDataTransferObject
): meeting is ResultsPageDataTransferObject {
  return meeting.Tag === 'Results'
}
