import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { BusyIndicator } from '@mobi/component-library/Feedback/BusyIndicator'
import { clearAllBetSlipItems } from '@mobi/betslip/Store/Bets'
import { TotalStake } from './TotalStake/TotalStake'

export const PlaceBetsButton = ({ disabled, isBusy }: { disabled: boolean; isBusy: boolean }) => (
  <Button
    color='positive'
    size='md'
    isFullWidth
    disabled={disabled}
    onClick={() => null} // ProposeAllBets
  >
    {isBusy ? (
      <BusyIndicator isBusy={isBusy} />
    ) : (
      <>
        Place Bets <TotalStake />
      </>
    )}
  </Button>
)

export const ConfirmBetsButton: React.FC<{ disabled?: boolean; isBusy: boolean }> = ({
  disabled = false,
  isBusy,
}) => (
  <GridButtonStyled
    disabled={disabled}
    onClick={() => null} // ConfirmAllBets
    color='primary'
    type='button'
  >
    {isBusy ? (
      <BusyIndicator isBusy={isBusy} />
    ) : (
      <>
        Confirm <TotalStake />
      </>
    )}
  </GridButtonStyled>
)

const handlePendingBetsClick = () => {
  // OverlayClose()
  // OnClose()
  // navigateToPendingBets()
}
export const PendingBetsButton = () => (
  <GridButtonStyled onClick={handlePendingBetsClick} color='secondary' type='button'>
    Pending Bets
  </GridButtonStyled>
)

const handleCloseBetslip = () => {
  // OverlayClose()
  // OnClose()
}
export const CloseButton = () => (
  <GridButtonStyled onClick={handleCloseBetslip} color='primary' type='button'>
    Close
  </GridButtonStyled>
)

export const LoginButton = ({ isBusy }: { isBusy: boolean }) => (
  <GridButtonStyled
    type='button'
    onClick={() => null} // showLogin
  >
    {isBusy ? <BusyIndicator isBusy={isBusy} /> : 'Log in to place bet'}
  </GridButtonStyled>
)

const handleEditBetsClick = () => {
  // EditBets()
  // RefreshBetslip()
}
export const EditBetsButton = ({ disabled }: { disabled: boolean }) => (
  <GridButtonStyled
    disabled={disabled}
    onClick={handleEditBetsClick}
    color='secondary'
    type='button'
  >
    Edit
  </GridButtonStyled>
)

const handleDepositClick = () => {
  // OverlayClose()
  // OnClose()
  // openDeposit()
}
export const DepositButton = () => (
  <GridButtonStyled type='button' onClick={handleDepositClick}>
    Deposit
  </GridButtonStyled>
)

const handleKeepBetsClick = () => {
  // KeepBets()
  // RefreshBetslip()
}
export const KeepBetsButton = () => (
  <GridButtonStyled color='secondary' type='button' onClick={handleKeepBetsClick}>
    Keep Bets
  </GridButtonStyled>
)

const GridButtonStyled = styled.button({
  width: '100%',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  whiteSpace: 'pre-line',
  height: '4rem',
})

export const ClearBetSlipButton = () => {
  // TODO: Use redux for isDisabled
  const isDisabled = false

  const dispatch = useDispatch()

  return (
    <Button
      disabled={isDisabled}
      onClick={() => dispatch(clearAllBetSlipItems())}
      color='secondary_color'
      size='md'
      isFullWidth
    >
      Clear Betslip
    </Button>
  )
}
