/* eslint-disable no-console */
import React from 'react'
import styled from '@emotion/styled'
import { GridCell, Grid } from '@mobi/component-library/Common/Grid'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import type { FobSelection, BetSlipItem } from '@mobi/betslip/types'
import { PriceChange, getPriceChange } from '@mobi/betslip/helpers/getPriceChange'
import { BetInfo } from '../../../Common/BetInfo/BetInfo'
import { isNonHandledError, isFatalMultiBetLegError } from '../../../../helpers/state'
import { InvestmentPriceStyled } from '../../../Common/BetInvestment.styles'

// const handleToggleMultiLegBetType = (id: string | null) => {
//   console.log('handleToggleMultiLegBetType id', id)
//   // ToggleMultiLegBetType(id)
// }

export const MultiItem: React.FC<{
  item: BetSlipItem
  disabled: boolean
  showBetTypeToggle: boolean
  isReceipt?: boolean
}> = ({ item, disabled, showBetTypeToggle, isReceipt = false }) => {
  const { id, isInMulti, multiLegBetType, shouldAllowPlaceInvestment } = item
  const hasMultiLegError =
    !!item.multiBetLegError && isNonHandledError(item.multiBetLegError.betErrorType)
  const prices = item.selection as FobSelection
  const priceToDisplay = multiLegBetType === 'P' ? prices.placePrice : prices.winPrice
  const priceChangeStatus: PriceChange =
    multiLegBetType === 'W'
      ? prices.winPriceLastSeen
        ? getPriceChange(prices.winPriceLastSeen, prices.winPrice)
        : 'NoChange'
      : prices.placePrice && prices.placePriceLastSeen
        ? getPriceChange(prices.placePriceLastSeen, prices.placePrice)
        : 'NoChange'

  if (isReceipt) {
    return (
      <Grid>
        <GridCell flexGrow={1}>
          <BetInfo item={item} />
        </GridCell>

        {!!item.multiBetLegError && isNonHandledError(item.multiBetLegError.betErrorType) ? (
          <ErrorBetLeg error={item.multiBetLegError.errorMessage} />
        ) : (
          <SuccessBetLeg
            showPlace={shouldAllowPlaceInvestment}
            showToggle={showBetTypeToggle}
            showPrice={priceToDisplay ?? 0}
            multiLegBetType={multiLegBetType}
            disabled={disabled}
            hasFatalError={isFatalMultiBetLegError(item)}
            priceChange={priceChangeStatus}
            itemId={item.id}
          />
        )}
      </Grid>
    )
  }

  return (
    <MultiItemStyled hasFatalError={isFatalMultiBetLegError(item)}>
      <Grid>
        <GridCell valign='middle' width='3.5rem'>
          <CheckboxInput
            id={id || ''}
            checked={isInMulti}
            label=''
            disabled={disabled || isFatalMultiBetLegError(item)}
            onChange={() => {
              // ToggleMultiBet(id)
            }}
          />
        </GridCell>

        <GridCell flexGrow={1}>
          <BetInfo item={item} />
        </GridCell>

        {hasMultiLegError ? (
          <ErrorBetLeg error={item.multiBetLegError?.errorMessage ?? ''} />
        ) : (
          <SuccessBetLeg
            showPlace={shouldAllowPlaceInvestment}
            showToggle={showBetTypeToggle}
            showPrice={priceToDisplay ?? 0}
            multiLegBetType={multiLegBetType}
            disabled={disabled}
            hasFatalError={isFatalMultiBetLegError(item)}
            priceChange={priceChangeStatus}
            itemId={item.id}
          />
        )}
      </Grid>
    </MultiItemStyled>
  )
}

function SuccessBetLeg({
  showPlace,
  showToggle,
  multiLegBetType,
  disabled,
  hasFatalError,
  showPrice,
  priceChange,
  itemId,
}: {
  showPlace: boolean
  showToggle: boolean
  multiLegBetType: 'W' | 'P' | undefined
  disabled: boolean
  hasFatalError: boolean
  showPrice: number
  priceChange: PriceChange
  itemId: string | null
}) {
  console.log('SuccessBetLeg', { hasFatalError, itemId, multiLegBetType })
  return (
    <>
      <GridCell align='right' valign='middle' flexGrow={0} flexBasis='auto'>
        {showPlace &&
          (showToggle ? (
            <div>
              {/* <Toggle
              toggledOn={multiLegBetType === BetLegType.Place}
              toggleOffCharacter='W'
              toggleOnCharacter='P'
              onToggle={() => handleToggleMultiLegBetType(itemId)}
              isDisabled={disabled || hasFatalError}
              type='allgreen'
            /> */}
              <pre>TODO: Toggle component</pre>
            </div>
          ) : (
            <div>
              {/* <ToggleIndicator character={multiLegBetType === BetLegType.Place ? 'P' : 'W'} /> */}
              <pre>TODO: ToggleIndicator component</pre>
            </div>
          ))}
      </GridCell>
      <GridCell align='right' valign='middle' flexGrow={0} flexBasis='5.5rem'>
        <InvestmentPriceStyled isEditable={!disabled} price={showPrice} priceChange={priceChange} />
      </GridCell>
    </>
  )
}

function ErrorBetLeg({ error }: { error: string }) {
  return (
    <GridCell align='right' valign='middle'>
      <ItemErrorTextStyled>{error}</ItemErrorTextStyled>
    </GridCell>
  )
}

// Styles

const MultiItemStyled = styled('li')<{ hasFatalError: boolean }>(
  {
    marginBottom: '0.5rem',
    padding: '0.5rem 0',
    borderBottom: '0.1rem solid #ccc',
    color: 'black',

    '&:last-child': {
      borderBottom: 'none',
    },
  },
  ({ hasFatalError }) =>
    hasFatalError && {
      backgroundColor: '#f55',
      borderBottom: 'none',
      paddingRight: '0.5rem',
      color: 'black',
    }
)

const ItemErrorTextStyled = styled('div')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: 'black',
})
