import React from 'react'
import Decimal from 'decimal.js'
import styled from '@emotion/styled'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import type { BetSlipItem, FobSelection } from '@mobi/betslip/types'
// import { SelectedSuperPick } from './Components/SuperPick/SelectedSuperPick'
// import { SuperPickAvailable } from './Components/SuperPick/SuperPickAvailable'
import { isStartingPriceSelection } from '@mobi/betslip/helpers/typeGuards'
// import { isSpecialUsed } from '@mobi/betslip/helpers/state'
import { calculateBoosts } from '@mobi/betslip/helpers/calculator'
import { getPriceChange, PriceChange } from '@mobi/betslip/helpers/getPriceChange'
import {
  InvestmentTextStyled,
  BetInvestmentGroupStyled,
  InvestmentPriceStyled,
  InvestmentAmountStyled,
} from '../../../../../../../Common/BetInvestment.styles'
import { isFatalErrorType } from '@mobi/betslip/helpers/state'
// import { ProjectedReturn } from './Components/ProjectedReturn/ProjectedReturn'

export const FobInvestment: React.FC<{
  item: BetSlipItem
  isLoggedIn: boolean
  isEditable: boolean
}> = ({ item, isEditable }) => {
  // TODO: Find best way to toggle can show inducements
  // const canShowInducements = true // useInducementService()

  // TODO: Hook up with state
  // const deselectSuperPick = () => null // DeselectSuperPick(item)

  const { selectedSuperPickOffer, investment, selection } = item
  const prices = selection as FobSelection

  const winPriceChange: PriceChange = prices.winPriceLastSeen
    ? getPriceChange(prices.winPriceLastSeen, prices.winPrice)
    : 'NoChange'

  const placePriceChange: PriceChange =
    prices.placePriceLastSeen && prices.placePrice
      ? getPriceChange(prices.placePriceLastSeen, prices.placePrice)
      : 'NoChange'

  const [winBoost, placeBoost] = calculateBoosts(selectedSuperPickOffer ?? null)

  const hasWinPrice = !!prices.winPrice

  const hasPlacePrice = !!prices.placePrice
  const hasSecondaryDisplay = hasWinPrice && hasPlacePrice
  const isStartingPriceBet = isStartingPriceSelection(selection)

  const handleEditBetslipWin = () => editBetslipItem({ item, investmentType: 'win', isEditable })
  const handleEditBetslipPlace = () =>
    editBetslipItem({ item, investmentType: 'place', isEditable })

  // const isSuperPickAvailable =
  // canShowInducements && isLoggedIn && !!item.specialOffers && item.specialOffers.length > 0
  // const shouldShowSuperPickButton = isSuperPickAvailable && !isSpecialUsed(item)
  // const shouldShowSuperPickSelected = isSuperPickAvailable && !!selectedSuperPickOffer

  return (
    <Grid>
      {item.isEachWayAvailable ? (
        <GridRow>
          <BetEachWayInvestmentGroupStyled isEditable={isEditable} onClick={handleEditBetslipWin}>
            <GridCell width='100%' valign='middle' padding='0.5rem 0'>
              <Grid>
                <div>
                  <InvestmentTextStyled isEditable={isEditable}>W</InvestmentTextStyled>

                  <InvestmentPriceStyled
                    price={new Decimal(prices.winPrice || 0).plus(winBoost).toNumber()}
                    priceChange={winPriceChange}
                    isEditable={isEditable}
                    shouldHighlight={winBoost > 0}
                  />
                </div>

                <div>
                  <InvestmentAmountStyled
                    isEditable={isEditable}
                    isBonusBet={!!investment.win.isBonusBet}
                  >
                    {investment.win.value}
                  </InvestmentAmountStyled>
                </div>
              </Grid>
            </GridCell>

            {item.isEachWay && (
              <GridCell width='100%' valign='middle' padding='0.5rem 0'>
                <Grid>
                  <GridCell>
                    <InvestmentTextStyled isEditable={isEditable}>P</InvestmentTextStyled>
                    <InvestmentPriceStyled
                      price={new Decimal(prices.placePrice || 0).plus(placeBoost).toNumber()}
                      priceChange={placePriceChange}
                      isEditable={isEditable}
                      shouldHighlight={placeBoost > 0}
                    />
                  </GridCell>

                  <GridCell align='right'>
                    <InvestmentAmountStyled isEditable={isEditable} isBonusBet={false}>
                      {investment.place.value}
                    </InvestmentAmountStyled>
                  </GridCell>
                </Grid>
              </GridCell>
            )}
          </BetEachWayInvestmentGroupStyled>
        </GridRow>
      ) : (
        <GridRow>
          {(hasWinPrice || isStartingPriceBet) && (
            <GridCell width={hasSecondaryDisplay ? '49%' : 'auto'}>
              <BetInvestmentGroupStyled isEditable={isEditable} onClick={handleEditBetslipWin}>
                <Grid>
                  <GridCell valign='middle'>
                    <InvestmentTextStyled isEditable={isEditable}>W</InvestmentTextStyled>
                    {isStartingPriceBet ? (
                      <InvestmentTextStyled isEditable={isEditable}>SP</InvestmentTextStyled>
                    ) : (
                      <InvestmentPriceStyled
                        price={new Decimal(prices.winPrice || 0).plus(winBoost).toNumber()}
                        priceChange={winPriceChange}
                        isEditable={isEditable}
                        shouldHighlight={winBoost > 0}
                      />
                    )}
                  </GridCell>

                  <GridCell valign='middle' align='right'>
                    <InvestmentAmountStyled
                      isEditable={isEditable}
                      isBonusBet={!!investment.win.isBonusBet}
                    >
                      {investment.win.value}
                    </InvestmentAmountStyled>
                  </GridCell>
                </Grid>
              </BetInvestmentGroupStyled>
            </GridCell>
          )}

          {hasSecondaryDisplay && <GridCell width='2%' />}

          {hasPlacePrice && (
            <GridCell width='49%'>
              <BetInvestmentGroupStyled isEditable={isEditable} onClick={handleEditBetslipPlace}>
                <Grid>
                  <GridCell>
                    <InvestmentTextStyled isEditable={isEditable}>P</InvestmentTextStyled>

                    <InvestmentPriceStyled
                      price={new Decimal(prices.placePrice || 0).plus(placeBoost).toNumber()}
                      priceChange={placePriceChange}
                      isEditable={isEditable}
                      shouldHighlight={placeBoost > 0}
                    />
                  </GridCell>

                  <GridCell align='right'>
                    <InvestmentAmountStyled
                      isEditable={isEditable}
                      isBonusBet={!!investment.place.isBonusBet}
                    >
                      {investment.place.value}
                    </InvestmentAmountStyled>
                  </GridCell>
                </Grid>
              </BetInvestmentGroupStyled>
            </GridCell>
          )}
        </GridRow>
      )}

      {/* <GridRow>
        <GridCell width='auto' valign='middle'>
          {shouldShowSuperPickSelected && (
            <SelectedSuperPick
              label={selectedSuperPickOffer?.title ?? ''}
              onDeselectClick={deselectSuperPick}
            />
          )}

          {shouldShowSuperPickButton && !shouldShowSuperPickSelected && (
            <SuperPickAvailable
              onClick={handleEditBetslipWin}
              disabled={false}
              isEditable={isEditable}
            />
          )}

          {item.isEachWay ? (
            <InvestmentTextStyled isEditable={isEditable}>Each Way</InvestmentTextStyled>
          ) : null}
        </GridCell>

        <GridCell width='auto' align='right'>
          <ProjectedReturn item={item} />
        </GridCell>
      </GridRow> */}
    </Grid>
  )
}

// =============
// Local Helpers
// =============

const editBetslipItem = ({
  item,
  investmentType = 'win',
  isEditable,
}: {
  item: BetSlipItem
  investmentType: Extract<keyof BetSlipItem['investment'], 'win' | 'place'>
  isEditable: boolean
}) => {
  // eslint-disable-next-line no-console
  console.log('editBetslipItem', investmentType)

  if (!isFatalErrorType(item.betErrorType) && isEditable) {
    if (item.investment.win.isBonusBet) {
      // SetActiveInvestment(InvestmentType.Win)
    } else if (item.investment.place.isBonusBet) {
      // SetActiveInvestment(InvestmentType.Place)
    } else {
      // SetActiveInvestment(investmentType)
    }

    // EditBetslipItem(item)
    // let shouldShowSuperPicks = !(isFobPropositionSelection(item.selection) && item.selection.marketCode === FixedOddsMarketCodes.Concession)

    // TODO: Checks all items if special is used already
    // shouldShowSuperPicks = !isSpecialUsed(item, items)

    if (item.investment.bonusBet) {
      // ToggleBonusBetUsage()
      // SetBonusBet(item.investment.bonusBet)
    }

    // ToggleBonusCashUsage(item.isUsingBonusCash ?? true)
  }
}

// ======
// Styles
// ======

const BetEachWayInvestmentGroupStyled = styled(BetInvestmentGroupStyled)<{
  isEditable: boolean
}>(
  {
    width: '100%',
    height: 'auto',
    minHeight: '4rem',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  ({ isEditable }) => ({
    backgroundColor: !isEditable ? '#eee' : undefined,
    boxShadow: !isEditable ? 'none' : undefined,
  })
)
