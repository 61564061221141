import { useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { type PayIdDetails } from '@mobi/api-types/src/account/betAccount'
import { get, put } from '../../Utils/network'
import { track } from '../../Utils/analytics'
import { reportErrorIfNeeded } from '../../Utils/sentry'
import { addCrumb } from '@mobi/utils'
import { queryKeys } from '../../Areas/Deposit/Utils'

export function usePayID({ accountNumber, payIdDetailList }: Params) {
  const [activePayID, setActivePayID] = useState(findActivePayId(payIdDetailList))
  const [status, setStatus] = useState<PayIdStatuses>(getInitialPayIdStatus(payIdDetailList))
  const queryClient = useQueryClient()

  // Official failureCount support is in @tanstack-query v4+
  // This is a ponyfill for it
  const failureCount = useRef(0)
  const pollingCount = useRef(0)

  const { mutate: generatePayId, isLoading: isGeneratePayIdLoading } = useMutation({
    mutationFn: () =>
      put('/api/eft-mgt/payid/requestcreate', {
        betAccountNumber: accountNumber,
      }),
    onError: (response: Response) => {
      switch (response.status) {
        case 500:
        case 504:
          setStatus('retry')
          break
        case 400:
        case 401:
        case 404:
        case 405:
        default:
          setStatus('error')
          break
      }

      incrementFailureCount()
      track('deposit_generate_payid_fail', {
        accountNumber,
        message: response.statusText,
      })
      reportErrorIfNeeded({
        message: `Unable to generate a PayId: ${response.statusText}`,
      })
      addCrumb('deposit', `PayId request to create failed with ${response.statusText}`)
    },
    onSuccess: () => {
      pollingCount.current = 0
      setStatus('payid-pending-activation')
      track('deposit_generate_payid_success', {
        accountNumber,
        attemptNumber: failureCount.current + 1,
      })
      addCrumb('deposit', 'PayId successful create')
    },
    onMutate: () => addCrumb('deposit', 'PayId request to create success'),
  })

  const { isLoading: isPayIdLoading } = useQuery({
    queryFn: () => get(`/api/eft-mgt/payid/get/${accountNumber}`),
    refetchInterval: 2 * 1000, // 2 seconds
    enabled: status === 'payid-pending-activation',
    refetchIntervalInBackground: true,
    onError: (response: Response) => {
      incrementFailureCount()
      setStatus('error')
      addCrumb('deposit', `PayId fetch failed with ${response.statusText}`)
    },
    onSuccess: (payIds: PayIdDetails[]) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.depositBetAccount] })
      const activePayId = findActivePayId(payIds)
      if (activePayId) {
        setActivePayID(activePayId)
        setStatus('generated')
        addCrumb('deposit', `PayId fetch successful activation`)
      } else {
        pollingCount.current++
        addCrumb(
          'deposit',
          `PayId fetch #${pollingCount.current} success but with status ${status}`
        )
        if (pollingCount.current >= LocalConstants.MAX_POLLING) {
          setStatus('error')
          addCrumb('deposit', `PayId fetch failed retried ${pollingCount.current} times`)
        }
      }
    },
  })

  const incrementFailureCount = () => {
    failureCount.current++
    if (failureCount.current >= 3) {
      setStatus('error')
    }
  }

  const generate = () => {
    if (isGeneratePayIdLoading || isPayIdLoading) return

    track('deposit_generate_payid', {
      accountNumber,
    })
    setStatus('generating-payid')
    generatePayId()
  }

  return {
    generate,
    activePayID,
    status,
  }
}

// #region Types

type Params = {
  accountNumber: number
  payIdDetailList: PayIdDetails[]
}

type PayIdStatuses =
  | 'no-payid'
  | 'generating-payid'
  | 'payid-pending-activation'
  | 'error'
  | 'retry'
  | 'generated'

// #endregion

// #region Utils

const findActivePayId = (payIds: PayIdDetails[]) =>
  payIds.find(payID => payID.CreationStatus === 'active')

const findPayIdPendingActivation = (payIds: PayIdDetails[]) =>
  payIds.find(payID => payID.CreationStatus === 'pending')

const getInitialPayIdStatus = (payIds: PayIdDetails[]): PayIdStatuses => {
  if (payIds.length === 0) return 'no-payid'
  if (findActivePayId(payIds)) return 'generated'
  if (findPayIdPendingActivation(payIds)) return 'payid-pending-activation'

  return 'error'
}

// #endregion

// #region Constants

const enum LocalConstants {
  MAX_POLLING = 10,
}

// #endregion
