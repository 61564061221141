import React, { type FC, Suspense, lazy } from 'react'
import styled from '@emotion/styled'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { useObservableProperties } from '@core/Utils/hooks'
import { MainContainer } from '@core/Components/Containers'
import { OverlayClose } from '@core/Components/Overlay'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { type DepositFlow } from '@mobi/account/Utils/types'

const App = lazy(() => import('@mobi/account/Areas/Deposit/App'))

export type DepositProps = {
  asModal?: boolean
  flow: DepositFlow
}

export const Deposit: FC<DepositProps> = ({ asModal = false, flow }) => {
  const { accountNumber, accountBalance } = useObservableProperties(
    userAccountState$,
    ['accountNumber', 'accountBalance'],
    {
      accountNumber: null,
      accountBalance: 0,
    }
  )
  const isBodyScrollLocked = accountNumber !== null && asModal
  const containerElementRef = useBodyScrollLock(isBodyScrollLocked)

  // This page cannot be loaded if the user isn't logged in anyway
  if (accountNumber === null) {
    return null
  }

  return (
    <Suspense fallback={<Spinner />}>
      <DepositMainContainer ref={containerElementRef}>
        <App
          accountNumber={accountNumber}
          accountBalance={accountBalance ?? 0}
          asModal={asModal}
          onClose={asModal ? OverlayClose : undefined}
          flow={flow}
        />
      </DepositMainContainer>
    </Suspense>
  )
}

const DepositMainContainer = styled(MainContainer)({
  overflowY: 'auto',
  overscrollBehavior: 'contain',
})
