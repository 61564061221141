import React from 'react'
import styled from '@emotion/styled'
import { Drawer } from '@mobi/component-library/Overlays/Drawer'
import { BetSlip } from '@mobi/betslip/App'
import { useFeature } from '@core/Utils/hooks'
import { useAppSelector } from '@core/Store/hooks'
import { HeaderPanel } from './Components/HeaderPanel'

export const BetSlipNew: React.FC = () => {
  const isNewBetSlipDesignActive = useFeature('NEW_BET_SLIP_DESIGN')
  const isOpen = useAppSelector(state => state.appHeader.isBetSlipNewOpen)

  if (!isNewBetSlipDesignActive) return null

  return (
    <Drawer isOpen={isOpen} shouldUseBodyScrollLock={false}>
      <HeaderPanel />

      <BetSlipParentWrapperStyled>
        <BetSlip />
      </BetSlipParentWrapperStyled>
    </Drawer>
  )
}

// ======
// Styles
// ======

const BetSlipParentWrapperStyled = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
})
