import React, { memo } from 'react'
import { PaymentMethodSelectionButton } from '../Buttons'
import { Grid } from '../Grid'
import type { DeviceType } from '../types'
import type { PaymentMethodsAll } from '../types'

type Props = {
  /**
   * The type of the device the payments will be displayed on.
   */
  deviceType: DeviceType
  /**
   * The selected payment method to make a deposit. Note that `ApplePay`
   * is only available on Safari and iOS, and `GooglePay` is not
   * available on iOS.
   */
  selectedPaymentMethod?: PaymentMethodsAll
  /**
   * Whether to show ApplePay as a payment method
   */
  showApplePay: boolean
  /**
   * Whether to show GooglePay as a payment method
   */
  showGooglePay: boolean
  /**
   * Whether to show BPay as a payment method
   */
  showBPay: boolean
  /**
   * Whether to show Cash as a payment method
   */
  showCash: boolean
  /**
   * Whether to show 'Scan my Bet' as a payment method
   */
  showScanMyBet: boolean
  /**
   * Whether to show PayID as a payment method
   */
  showPayID: boolean
  /**
   * The click handler for the payment methods. When clicked, passes
   * the associated payment method as the first parameter.
   */
  onClick: (paymentMethod: PaymentMethodsAll) => void
}

/**
 * A grid based layout of available of supported payment methods that are supported on a given platform.
 *
 * ## Supported payment methods
 *
 * - Credit card
 * - Paypal
 * - Google Pay
 * - Apple Pay
 * - Other (self-serve deposit methods, e.g. BPay, Cash, PayID)
 *
 * ## Compatibility
 *
 * |             | Browser (Safari) | Browser (Non-Safari) | Native iOS | Native Android |
 * |-------------|------------------|----------------------|------------|----------------|
 * | Debit Card  | ✅               | ✅                   | ✅         | ✅             |
 * | Paypal      | ✅               | ✅                   | ✅         | ✅             |
 * | Apple Pay   | ✅               | ❌                   | ✅         | ❌             |
 * | Google Pay  | ✅               | ✅                   | ❌         | ✅             |
 * | BPay        | ✅               | ✅                   | ✅         | ✅             |
 * | Cash        | ✅               | ✅                   | ✅         | ✅             |
 * | Scan My Bet | ❌               | ❌                   | ✅         | ✅             |
 * | PayID       | ✅               | ✅                   | ✅         | ✅             |
 *
 */
export const PaymentMethods = memo<Props>(
  ({
    selectedPaymentMethod = 'CreditCard',
    showScanMyBet,
    showApplePay,
    showGooglePay,
    showBPay,
    showCash,
    showPayID,
    onClick,
    deviceType,
  }) => {
    const isDesktop = deviceType === 'desktop'

    return (
      <Grid cols={deviceType === 'desktop' ? 1 : 2}>
        <PaymentMethodSelectionButton
          size='sm'
          title='Debit Card'
          selected={selectedPaymentMethod === 'CreditCard'}
          paymentMethod='CreditCard'
          isDesktop={isDesktop}
          onClick={onClick}
        />

        <PaymentMethodSelectionButton
          size='sm'
          title='PayPal'
          selected={selectedPaymentMethod === 'PayPal'}
          paymentMethod='PayPal'
          isDesktop={isDesktop}
          onClick={onClick}
        />

        {showApplePay && (
          <PaymentMethodSelectionButton
            size='sm'
            title='Apple Pay'
            selected={selectedPaymentMethod === 'ApplePay'}
            paymentMethod='ApplePay'
            isDesktop={isDesktop}
            onClick={onClick}
          />
        )}

        {showGooglePay && (
          <PaymentMethodSelectionButton
            size='sm'
            title='Google Pay'
            selected={selectedPaymentMethod === 'GooglePay'}
            paymentMethod='GooglePay'
            isDesktop={isDesktop}
            onClick={onClick}
          />
        )}

        {showPayID && (
          <PaymentMethodSelectionButton
            size='sm'
            title='PayID'
            selected={selectedPaymentMethod === 'PayID'}
            paymentMethod='PayID'
            isDesktop={isDesktop}
            onClick={onClick}
          />
        )}

        {showBPay && (
          <PaymentMethodSelectionButton
            size='sm'
            title='BPay'
            selected={selectedPaymentMethod === 'BPay'}
            paymentMethod='BPay'
            isDesktop={isDesktop}
            onClick={onClick}
          />
        )}

        {showCash && (
          <PaymentMethodSelectionButton
            size='sm'
            title='Cash'
            selected={selectedPaymentMethod === 'Cash'}
            paymentMethod='Cash'
            isDesktop={isDesktop}
            onClick={onClick}
          />
        )}

        {showScanMyBet && (
          <PaymentMethodSelectionButton
            size='sm'
            title='Scan my bet'
            selected={selectedPaymentMethod === 'ScanMyBet'}
            paymentMethod='ScanMyBet'
            isDesktop={isDesktop}
            onClick={onClick}
          />
        )}
      </Grid>
    )
  }
)
