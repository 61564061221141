import React from 'react'
import { AppHeaderButtonStyled as Button } from '../../Buttons/Buttons.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { setBetSlipNewIsOpen, toggleIsAppHeaderVisible } from '@core/Areas/AppHeader/Store'
import {
  BetslipButtonBadgeStyled as Badge,
  BetslipButtonBadgeNumberStyled as Number,
  BadgeAnimation,
} from './BetslipButton/BetslipButton.styles'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { store } from '@core/Store'
import { selectBetSlipItemCount } from '@mobi/betslip/Store/Bets/selectors'

export const BetslipButtonNew = () => {
  const dispatch = useAppDispatch()

  const [count, prevCount] = useBetslipCountNew()
  const animation = getBadgeAnimation(count, prevCount)

  React.useEffect(() => {
    if (prevCount === null || prevCount === count) return

    const hasCountIncreased = count > prevCount
    const toastText = hasCountIncreased ? 'Added to' : 'Removed from'

    // TODO: replace with new toast
    RegisterToast({
      message: `${toastText} Betslip`,
      position: 'top',
      id: `Betslip Button Event - ${toastText} Betslip`,
    })

    if (hasCountIncreased && window.pageYOffset > 0) {
      store.dispatch(toggleIsAppHeaderVisible(true))
    }
  }, [count, prevCount])

  return (
    <Button onClick={() => dispatch(setBetSlipNewIsOpen(true))}>
      {!!count && (
        <Badge>
          <Number key={count} animation={animation}>
            <span aria-label='Betslip Item Count' data-testid='betslip-item-count'>
              {count}
            </span>
            {animation && <span aria-hidden>{count + (animation === 'up' ? -1 : 1)}</span>}
          </Number>
        </Badge>
      )}
      <Icon type='betslip' title='Betslip' size='inherit' />
    </Button>
  )
}

// Local Helpers
const useBetslipCountNew = (): [count: number, prevCount: number | null] => {
  const prevCountRef = React.useRef<number | null>(null)
  const count = useAppSelector(selectBetSlipItemCount)
  React.useEffect(() => {
    prevCountRef.current = count
  }, [count])

  return [count, prevCountRef.current]
}

function getBadgeAnimation(count: number, prevCount: number | null): BadgeAnimation {
  if (prevCount === null) return null
  return count > prevCount ? 'up' : 'down'
}
