import React from 'react'
import type { BetSlipBetsState } from '@mobi/betslip/types'
import {
  FormulaContainerStyled,
  FormulaTextStyled,
  FormulaComboTextStyled,
  FormulaInvestmentStyled,
  FormulaItemStyled,
  FormulaTitleTextStyled,
  FormulaCostChildStyled,
  FormulaCostAmountStyled,
  FormulaCostContainerStyled,
  MultiFormulaExpandablePanelStyled,
} from './MultiFormula.styles'
import { GridCell, Grid, GridRow } from '@mobi/component-library/Common/Grid'
import { InvestmentAmountStyled } from '../../../Common/BetInvestment.styles'
import {
  MIN_LEGS_IN_MULTI,
  MAX_LEGS_FOR_MULTI_FORMULA,
  MULTI_FORMULA_COMBINATIONS,
} from '../../../../helpers/constants'
import { getBetsInMulti, isValidMultiInvestmentForLegs } from '../../../../helpers/state'

enum FormulaTitles {
  f1 = 'Singles',
  f2 = 'Doubles',
  f3 = 'Trebles',
  f4 = 'Pick 4',
  f5 = 'Pick 5',
}

type EnumLiteralsOf<T extends object> = T[keyof T]
export type FormulaFields = EnumLiteralsOf<typeof FormulaFields>
export const FormulaFields = {
  f1: 'f1',
  f2: 'f2',
  f3: 'f3',
  f4: 'f4',
  f5: 'f5',
} as const

interface InvestmentClickArgs {
  investment: number
  subTitle: string
  combos: number
  field: string
  isFieldEditable: boolean
}

const handleInvestmentClick = ({
  // investment = 0,
  // subTitle,
  // combos,
  // field,
  isFieldEditable,
}: InvestmentClickArgs): void => {
  if (!isFieldEditable) {
    return
  }
  // SetQuickEditInvestment(investment)
  // store.dispatch(
  //   openModal({
  //     id: QUICK_EDIT_MODAL,
  //     modalComponent: QuickEdit,
  //     modalComponentProps: {
  //       title: 'Formula',
  //       subTitle,
  //       combos,
  //       formulaField: field,
  //     },
  //     color: 'dark',
  //   })
  // )
}

type MultiFormulaProps = Pick<BetSlipBetsState, 'multiInvestment'> & {
  isEditable: boolean
  multiItemsCount: number
}

type MultiFormulaComboKeys =
  keyof (typeof MULTI_FORMULA_COMBINATIONS)[keyof typeof MULTI_FORMULA_COMBINATIONS]

class MultiFormulaComponent extends React.PureComponent<MultiFormulaProps> {
  public render() {
    const { multiItemsCount: count, isEditable, multiInvestment } = this.props

    const shouldEnableSingles = isValidMultiInvestmentForLegs(multiInvestment, count)

    if (multiInvestment.bonusBetId) {
      return `Formulas can't be used with Bonus Bet`
    }

    const formulaFields: JSX.Element[] = []
    for (let i = 1; i <= count - 1; i++) {
      const current: FormulaFields = FormulaFields[`f${i}` as keyof typeof FormulaFields]
      const formulaCombosForLegs =
        MULTI_FORMULA_COMBINATIONS[current][`legs${count}` as MultiFormulaComboKeys]
      const formulaTitle = FormulaTitles[current]
      const formulaInvestment = multiInvestment[current]

      const isFieldEditable = i === 1 ? shouldEnableSingles && isEditable : isEditable

      formulaFields.push(
        <FormulaItemStyled key={formulaTitle}>
          <Grid>
            <GridRow>
              <GridCell width='33%' valign='middle'>
                <FormulaTitleTextStyled>{formulaTitle}</FormulaTitleTextStyled>
              </GridCell>

              <GridCell align='center' valign='middle'>
                <FormulaComboTextStyled>
                  Combos <FormulaTextStyled>x {formulaCombosForLegs}</FormulaTextStyled>
                </FormulaComboTextStyled>
              </GridCell>

              <GridCell width='33%'>
                <FormulaInvestmentStyled
                  isEditable={isFieldEditable}
                  onClick={() =>
                    handleInvestmentClick({
                      investment: formulaInvestment,
                      subTitle: formulaTitle,
                      combos: formulaCombosForLegs,
                      field: current,
                      isFieldEditable,
                    })
                  }
                >
                  <InvestmentAmountStyled isEditable>{formulaInvestment}</InvestmentAmountStyled>
                </FormulaInvestmentStyled>
              </GridCell>
            </GridRow>
          </Grid>

          {formulaInvestment > 0 && (
            <FormulaCostContainerStyled>
              <FormulaCostChildStyled>
                Cost{' '}
                <FormulaCostAmountStyled>
                  ${(formulaInvestment * formulaCombosForLegs).toFixed(2)}
                </FormulaCostAmountStyled>
              </FormulaCostChildStyled>
            </FormulaCostContainerStyled>
          )}
        </FormulaItemStyled>
      )
    }

    return (
      <>
        <FormulaContainerStyled>{formulaFields}</FormulaContainerStyled>
      </>
    )
  }
}

export const MultiFormula = () => {
  // TODO: Use state
  const betsInMulti = getBetsInMulti([])
  const hasProposed = false
  const isBusy = false

  const count = betsInMulti.length

  if (!(count >= MIN_LEGS_IN_MULTI && count <= MAX_LEGS_FOR_MULTI_FORMULA)) {
    return null
  }

  return (
    <MultiFormulaExpandablePanelStyled>
      {/* <FormulaExpandablePanelHeaderComponent /> */}
      <MultiFormulaComponent
        multiItemsCount={count}
        isEditable={!hasProposed && !isBusy}
        multiInvestment={{} as BetSlipBetsState['multiInvestment']}
      />
    </MultiFormulaExpandablePanelStyled>
  )
}
