import styled from '@emotion/styled'
import { MoneyGrouped, MoneyGroupedProps } from './Money/MoneyGrouped'

export const BetInvestmentGroupStyled = styled.div<{ isEditable: boolean }>(
  {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'stretch',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    width: 'auto',
    height: '4rem',
    padding: '0 0.5rem',
    marginBottom: '0.5rem',
    border: '1px solid #ccc',
    backgroundColor: 'white',
    boxShadow: `0 0.1rem 0.2rem #eee`,
    fontSize: '1.4rem',
    cursor: 'pointer',
  },
  ({ isEditable }) => ({
    backgroundColor: !isEditable ? '#eee' : undefined,
    boxShadow: !isEditable ? 'none' : undefined,
  })
)

type InvestmentStyledProps = MoneyGroupedProps & {
  isEditable: boolean
  shouldHighlight?: boolean
  isBonusBet?: boolean
}

export const InvestmentStyled = styled(MoneyGrouped)<InvestmentStyledProps>(
  {
    fontWeight: 'bold',
    fontSize: '1.6rem',
    zIndex: 'unset',
  },
  ({ isEditable, isBonusBet }) => ({
    color: isEditable ? '#222' : '#aaa',
    backgroundColor: isBonusBet ? 'orange' : undefined,
    padding: isBonusBet ? '0.1em 0.5em' : undefined,
    borderRadius: isBonusBet ? '0.4em' : undefined,
  })
)

export const InvestmentPriceStyled = styled(MoneyGrouped)<InvestmentStyledProps>(
  {
    fontWeight: 'normal',
    fontSize: '1.2rem',
    zIndex: 0,
    position: 'relative',
    alignItems: 'center',
  },
  ({ isEditable, priceChange, shouldHighlight }) => {
    let color: string | undefined = isEditable ? '#222' : '#aaa'

    // if price change and no highlight, leave color to be decided by it children
    if (priceChange && priceChange !== 'NoChange' && !shouldHighlight) {
      color = undefined
    }

    return shouldHighlight
      ? {
          color,
          fontWeight: 'bold',
          '&::before': {
            backgroundColor: 'orange',
            borderRadius: '0.5rem',
            content: '" "',
            height: '100%',
            width: priceChange && priceChange !== 'NoChange' ? 'calc(100% + 1.5rem)' : '100%',
            top: '50%',
            left: '-0.2rem',
            right: '-0.2rem',
            padding: '0.1rem 0.2rem',
            position: 'absolute',
            transform: 'translateY(-50%)',
            zIndex: -1,
          },
        }
      : {
          color,
        }
  }
)

export const InvestmentAmountStyled = styled.span<{ isEditable: boolean; isBonusBet?: boolean }>(
  {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.6rem',
    margin: 0,
    position: 'relative',
    color: '#222',
  },
  ({ isEditable, isBonusBet }) => ({
    color: !isEditable ? '#aaa' : undefined,
    backgroundColor: isBonusBet ? 'orange' : undefined,
    padding: isBonusBet ? '0.1em 0.5em' : undefined,
    borderRadius: isBonusBet ? '0.4em' : undefined,
  })
)

export const InvestmentTextStyled = styled.span<{ isEditable: boolean }>(
  {
    paddingRight: '0.5rem',
    display: 'inline-block',
    fontSize: '1.2rem',
    margin: 0,
    position: 'relative',
    color: '#222',
  },
  ({ isEditable }) => ({
    color: !isEditable ? '#aaa' : undefined,
  })
)
