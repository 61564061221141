import {
  LDContext,
  LDClient,
  initialize as initializeLaunchDarkly,
  LDFlagChangeset,
} from 'launchdarkly-js-client-sdk'
import { OnFeatureChange } from './signals'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { FeatureFlags } from './driver'
import { FeatureFlags as FeatureFlagKeys, getFlagDefaults } from '@mobi/settings'
import { AppVersion, OS, MajorVersion } from '@mobi/web-native-comms/web'
import type { ServerConfig } from '@core/Data/ServerConfig/types'

let ldClient: LDClient

const ANONYMOUS_CONTEXT: LDContext = {
  kind: 'user',
  key: 'Guest',
  anonymous: true,
  custom: getCustomUserAttributes(),
}

interface LaunchDarklyInitializeProps {
  clientId: string
  bootstrapAccountNumber: string | undefined
  bootstrapClientFlags: FeatureFlags
  bootstrapServerFlags: FeatureFlags
}

const initialize = ({
  clientId,
  bootstrapAccountNumber,
  bootstrapClientFlags,
  bootstrapServerFlags,
}: LaunchDarklyInitializeProps): Promise<FeatureFlags> => {
  return new Promise(resolve => {
    if (ldClient) {
      throw new Error('Launch Darkly client has already been initialised')
    }

    if (!bootstrapClientFlags) {
      bootstrapClientFlags = {}
    }

    // Optionally initialize state, pending retrieval of flags from the Launch Darkly API)
    if (bootstrapServerFlags) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { $valid, $flagsState, ...initialFlags } = bootstrapServerFlags
      // remove null/undefined value from server bootstrap object
      for (let propName in initialFlags) {
        if (initialFlags[propName] === null || initialFlags[propName] === undefined) {
          delete initialFlags[propName]
        }
      }

      OnFeatureChange({
        features: { ...bootstrapClientFlags, ...initialFlags },
        isUserAnonymous: !bootstrapAccountNumber,
      })
    }

    let context: LDContext = ANONYMOUS_CONTEXT

    if (bootstrapAccountNumber) {
      context = {
        key: bootstrapAccountNumber,
        custom: getSimpleCustomUserAttributes(),
      }
    }

    ldClient = initializeLaunchDarkly(clientId, context, {
      streaming: true,
      sendEventsOnlyForVariation: false,
    })

    ldClient.on('ready', async () => {
      const flags = Object.keys(bootstrapClientFlags).reduce(
        (accumulator, flag) => {
          accumulator[flag] = ldClient.variation(flag, bootstrapClientFlags[flag])
          return accumulator
        },
        {} as { [key: string]: unknown }
      )

      const accountNumber = (await userAccountState$.take(1).toPromise()).accountNumber

      OnFeatureChange({ features: flags, isUserAnonymous: !accountNumber })
      subscribeToLaunchDarklyChanges()
      subscribeToUserStateChanges()
      resolve(flags)
    })
  })
}

const subscribeToLaunchDarklyChanges = () => {
  ldClient.on('change', async (changeset: LDFlagChangeset) => {
    const features = Object.keys(changeset).reduce(
      (accumulator, key) => {
        accumulator[key] = changeset[key].current
        return accumulator
      },
      {} as { [key: string]: unknown }
    )

    const accountNumber = (await userAccountState$.take(1).toPromise()).accountNumber

    OnFeatureChange({ features, isUserAnonymous: !accountNumber })
  })
}

const subscribeToUserStateChanges = () => {
  userAccountState$
    .map(record => ({
      accountNumber: record.accountNumber,
      isLoggedIn: record.isLoggedIn,
    }))
    .distinctUntilChanged()
    .subscribe(async ({ accountNumber, isLoggedIn }) => {
      if (isLoggedIn === null) {
        return
      }

      const ldContext: LDContext = accountNumber
        ? { kind: 'user', key: accountNumber.toString(), custom: getCustomUserAttributes() }
        : ANONYMOUS_CONTEXT

      const updatedFlags = await ldClient.identify(ldContext)

      OnFeatureChange({ features: updatedFlags, isUserAnonymous: !accountNumber })
    })
}

export const initializeLaunchDarklyServiceWithConfig = (
  config: ServerConfig
): Promise<FeatureFlags> => {
  const serverFlags = window.LDFlags
  let accountNumber = ''

  const serverProvidedAccountNumber = (document.getElementById('AccountNumber') as HTMLInputElement)
    ?.value
  if (serverProvidedAccountNumber) {
    accountNumber = serverProvidedAccountNumber
  }
  return initialize({
    clientId: config.launchDarklyClientID,
    bootstrapAccountNumber: accountNumber,
    bootstrapClientFlags: getFlagDefaults(),
    bootstrapServerFlags: serverFlags,
  })
}

export type FeatureFlagKey = (typeof FeatureFlagKeys)[keyof typeof FeatureFlagKeys]['key']

function getSimpleCustomUserAttributes() {
  return {
    clientVersion: Number.parseFloat(`${VERSION_MAJOR}.${VERSION_MINOR}`),
    nativeAppVersion: AppVersion || 0,
  }
}

function getCustomUserAttributes() {
  return {
    ...getSimpleCustomUserAttributes(),
    os: OS,
    systemMajorVersion: MajorVersion,
  }
}
