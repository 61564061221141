import React from 'react'
import Decimal from 'decimal.js'
import { GridCell, Grid, GridRow } from '@mobi/component-library/Common/Grid'
import type { ToteSelection, BetSlipItem } from '@mobi/betslip/types'
import {
  isToteSelection,
  isNoveltyBetType,
  isAllUpSelection,
} from '@mobi/betslip/helpers/typeGuards'
import {
  BetInvestmentGroupStyled,
  InvestmentTextStyled,
  InvestmentAmountStyled,
} from '@mobi/betslip/Components/Common/BetInvestment.styles'
import { isFatalErrorType } from '@mobi/betslip/helpers/state'

export const ToteInvestment: React.FC<{ item: BetSlipItem; isEditable: boolean }> = ({
  item,
  isEditable,
}) => {
  const { investment, selection, shouldAllowPlaceInvestment } = item
  const shouldDisplayFlexi = isToteSelection(selection) && isNoveltyBetType(selection.betType)
  const shouldDisplayNumberOfCombinations = isAllUpSelection(selection)

  const toteBetType = (item.selection as ToteSelection).betType
  const hasSecondaryDisplay =
    shouldAllowPlaceInvestment || shouldDisplayFlexi || shouldDisplayNumberOfCombinations

  let flexi
  if (shouldDisplayFlexi) {
    flexi = item.numberOfCombinations
      ? Math.floor(((investment.win.value * 100) / item.numberOfCombinations) * 100) / 100
      : 0
  }

  const handleEditBetslipWin = () => editBetslipItem({ item, investmentType: 'win', isEditable })
  const handleEditBetslipPlace = () =>
    editBetslipItem({ item, investmentType: 'place', isEditable })

  return (
    <GridRow>
      <GridCell width={hasSecondaryDisplay ? '49%' : 'auto'}>
        <BetInvestmentGroupStyled isEditable={isEditable} onClick={handleEditBetslipWin}>
          <Grid>
            <GridCell>
              <InvestmentTextStyled isEditable={isEditable}>
                {toteBetType === 'Win & Place' ? 'W' : 'Spend'}
              </InvestmentTextStyled>
            </GridCell>

            <GridCell align='right'>
              {investment !== undefined && (
                <InvestmentAmountStyled isEditable={isEditable}>
                  {new Decimal(investment.win.value || 0).toNumber()}
                </InvestmentAmountStyled>
              )}
            </GridCell>
          </Grid>
        </BetInvestmentGroupStyled>
      </GridCell>

      {hasSecondaryDisplay && <GridCell width='2%' />}

      {shouldAllowPlaceInvestment && (
        <GridCell width='49%'>
          <BetInvestmentGroupStyled isEditable={isEditable} onClick={handleEditBetslipPlace}>
            <Grid>
              <GridCell>
                <InvestmentTextStyled isEditable={isEditable}>P</InvestmentTextStyled>
              </GridCell>
              <GridCell align='right'>
                {investment !== undefined && (
                  <InvestmentAmountStyled isEditable={isEditable}>
                    {new Decimal(investment.place.value || 0).toNumber()}
                  </InvestmentAmountStyled>
                )}
              </GridCell>
            </Grid>
          </BetInvestmentGroupStyled>
        </GridCell>
      )}

      {shouldDisplayFlexi && (
        <GridCell width='49%'>
          <BetInvestmentGroupStyled isEditable={isEditable} onClick={handleEditBetslipWin}>
            <Grid>
              <GridCell>
                <InvestmentTextStyled isEditable={isEditable}>Flexi</InvestmentTextStyled>
              </GridCell>
              <GridCell align='right'>
                {flexi !== undefined && (
                  <InvestmentTextStyled isEditable={isEditable}>{`${flexi}%`}</InvestmentTextStyled>
                )}
              </GridCell>
            </Grid>
          </BetInvestmentGroupStyled>
        </GridCell>
      )}

      {shouldDisplayNumberOfCombinations && (
        <GridCell width='49%'>
          <BetInvestmentGroupStyled isEditable={isEditable} onClick={handleEditBetslipWin}>
            <Grid>
              <GridCell>
                <InvestmentTextStyled isEditable={isEditable}>Combos</InvestmentTextStyled>
              </GridCell>
              <GridCell align='right'>
                {item.numberOfCombinations !== undefined && (
                  <InvestmentTextStyled isEditable={isEditable}>
                    {`${item.numberOfCombinations}`}
                  </InvestmentTextStyled>
                )}
              </GridCell>
            </Grid>
          </BetInvestmentGroupStyled>
        </GridCell>
      )}
    </GridRow>
  )
}

// =============
// Local Helpers
// =============

const editBetslipItem = ({
  item,
  investmentType = 'win',
  isEditable,
}: {
  item: BetSlipItem
  investmentType: Extract<keyof BetSlipItem['investment'], 'win' | 'place'>
  isEditable: boolean
}) => {
  // eslint-disable-next-line no-console
  console.log('editBetslipItem', investmentType)

  if (!isFatalErrorType(item.betErrorType) && isEditable) {
    if (item.investment.win.isBonusBet) {
      // SetActiveInvestment(InvestmentType.Win)
    } else if (item.investment.place.isBonusBet) {
      // SetActiveInvestment(InvestmentType.Place)
    } else {
      // SetActiveInvestment(investmentType)
    }

    // EditBetslipItem(item)
    // let shouldShowSuperPicks = !(isFobPropositionSelection(item.selection) && item.selection.marketCode === FixedOddsMarketCodes.Concession)

    // TODO: Checks all items if special is used already
    // shouldShowSuperPicks = !isSpecialUsed(item, items)

    if (item.investment.bonusBet) {
      // ToggleBonusBetUsage()
      // SetBonusBet(item.investment.bonusBet)
    }

    // ToggleBonusCashUsage(item.isUsingBonusCash ?? true)
  }
}
